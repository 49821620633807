import React, { Component } from 'react'
import Contadores from './Contadores';
import Notificaciones from './Notificaciones';
import GBatery from './GBatery';
import GSignal from './GSignal';
import GTempE from './GTempE';
import GCaudal from './GCaudal';
import GVolumen from './GVolumen';
import VolSemanal from './VolSemanal';
import VolMensual from './VolMensual';
import VolAcumulado from './VolAcumulado';

export class Page extends Component {

  render() {

    function renderi(num) {
      

      if (num.length > 0) {
        return (
          <div className="page">

            <header className="header">
              <nav className="navbar">
                <div className="container-fluid">
                  <div className="navbar-holder d-flex align-items-center justify-content-between">
                    <div className="navbar-header"><button id="toggle-btn" className="menu-btn btn-dark"><i className="icon-bars"> </i></button><span href="index.html" className="navbar-brand">
                      <div className="brand-text d-none d-md-inline-block"><span style={{ color: "#ccc" }}>{num[0].nombresitio[0].nombre} </span><strong className="text-primary"> {num[6].estatus}</strong></div></span></div>
                    <Notificaciones />
                  </div>
                </div>
              </nav>
            </header>

            <Contadores lecturas={num[2].ultimalectura} />

            <section className="dashboard-header ">
              <div className="container-fluid">
                <div className="row d-flex align-items-md-stretch">



                  <GCaudal  lecturas={num[1].lecturas} />
                  <GVolumen lecturas={num[1].lecturas} />
                  <GBatery lecturas={num[1].lecturas}  />
                  <GTempE lecturas={num[1].lecturas}  />
                  <GSignal lecturas={num[1].lecturas}  />
                </div>
              </div>
            </section>

            <section className="statistics">
              <div className="container-fluid">
                <div className="row d-flex">
                  <VolAcumulado voldia={num[5].voldia} />
                  <VolSemanal volsem={num[4].volsem}  />
                  <VolMensual volmes={num[3].volmes}  />



                </div>
              </div>
            </section>

            <footer className="main-footer">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-sm-6">
                    <p>Instrutrónica -Liderti &copy; 2019</p>
                  </div>
                  <div className="col-sm-6 text-right">
                    <p>Version 5.0.1</p>
                  </div>
                </div>
              </div>
            </footer>
          </div>
        )
      } else {
        return (
          <div>Cargando</div>
        )
      }
    }

    return renderi(this.props.lecturas);
  }
}

export default Page

