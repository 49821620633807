import React, { Component } from 'react';

import Navigation from './Navigation';
import CargarPuntos from './CargarPuntos3';

export class Mapa extends Component {
    render() {
        return (
            <div>
               <Navigation/>                
               <CargarPuntos />
            </div>
        )
    }
}

export default Mapa
