import React, { Component } from "react";
import minaimg from "./img/minaata.jpg";
import logo from "../mapa/img/minaperulogo.png";
import "../dashboard/css/nav.css";
import auth from "../../auth";
import { setInStorage, getFromStorage } from "../util/storage";

export class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      token: "",
      signUpError: "",
      signUpEmail: "",
      signUpPassword: ""
    };

    this.onTextboxChangeSignUpEmail =
      this.onTextboxChangeSignUpEmail.bind(this);
    this.onTextboxChangeSignUpPassword =
      this.onTextboxChangeSignUpPassword.bind(this);
    this.onSignUp = this.onSignUp.bind(this);
  }

  onTextboxChangeSignUpEmail(event) {
    this.setState({
      signUpEmail: event.target.value
    });
  }

  onTextboxChangeSignUpPassword(event) {
    this.setState({
      signUpPassword: event.target.value
    });
  }
  componentDidMount() {
    const obj = getFromStorage("pruebas_app");
    if (obj && obj.token) {
      const { token } = obj;
      // Verify token
      fetch(
        "https://api.instrutronica-iot.com/api/pruebas/verify?token=" + token
      )
        .then((res) => res.json())
        .then((json) => {
          if (json.success) {
            auth.login();
            this.props.history.push("/mapa");
          } else {
            this.setState({
              isLoading: false
            });
          }
        });
    } else {
      this.setState({
        isLoading: false
      });
    }
  }

  onSignUp = async (e) => {
    e.preventDefault();

    // Grab state
    const { signUpEmail, signUpPassword } = this.state;

    // Post request to backend
    fetch("https://api.instrutronica-iot.com/api/pruebas/signup", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        user: signUpEmail,
        password: signUpPassword
      })
    })
      .then((res) => res.json())
      .then((json) => {
        if (json.success) {
          setInStorage("pruebas_app", { token: json.token });
          setInStorage("tipo", { tipo: json.tipo });
          auth.login();
          this.props.history.push("/mapa");
        } else {
          this.setState({
            signUpError: json.message,
            isLoading: false
          });
        }
      });
  };
  /*<button onClick={()=>{
                  auth.login(()=>{
                      this.props.history.push("/mapa");
                  });
              }} className="btn btn-lg btn-block btn-primary mb-3">Acceder</button>*/

  render() {
    const { signUpEmail, signUpPassword, signUpError } = this.state;

    return (
      <div className="container-fluid px-3">
        <div className="row min-vh-100">
          <div className="col-md-5 col-lg-6 col-xl-4 px-lg-5 d-flex align-items-center">
            <div className="w-100 py-5">
              <div className="text-center">
                <img
                  src={logo}
                  alt="Logo Mina"
                  style={{ maxWidth: "6rem" }}
                  className="img-fluid mb-4"
                />
                <h1 className="display-4 mb-3">Acceso Dashboard</h1>
              </div>{" "}
              {signUpError ? <p>{signUpError}</p> : null}
              <form className="form-validate">
                <div className="form-group">
                  <label>Usuario</label>
                  <input
                    value={signUpEmail}
                    type="text"
                    placeholder="Usuario"
                    autoComplete="off"
                    required
                    data-msg="Por favor ingrese su usuario"
                    onChange={this.onTextboxChangeSignUpEmail}
                    className="form-control"
                  />
                </div>
                <div className="form-group mb-4">
                  <div className="row">
                    <div className="col">
                      <label>Contraseña</label>
                    </div>
                  </div>
                  <input
                    value={signUpPassword}
                    placeholder="Contraseña"
                    type="password"
                    required
                    data-msg="Por favor ingrese su contraseña"
                    onChange={this.onTextboxChangeSignUpPassword}
                    className="form-control"
                  />
                </div>

                <button
                  onClick={this.onSignUp}
                  className="btn btn-lg btn-block btn-primary mb-3"
                >
                  Acceder
                </button>
              </form>
            </div>
          </div>
          <div className="col-12 col-md-7 col-lg-6 col-xl-8 d-none d-lg-block">
            <div
              style={{ backgroundImage: "url(" + minaimg + ")" }}
              className="bg-cover h-100 mr-n3"
            ></div>
          </div>
        </div>
      </div>
    );
  }
}

export default Login;
