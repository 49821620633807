import React, { Component } from 'react'

export class VolMensual extends Component {
    render() {
        return (
            <div className="col-lg-4">
              
              <div className="card income text-center">
                <div className="icon"><i className="icon-line-chart"></i></div>
                <div className="number">{(this.props.volmes1)}</div><strong className="text-primary">Volumen 1 Mensual M3</strong>
                <p>Volumen consumido en este mes.</p>
              </div>
            </div>
        )
    }
}

export default VolMensual
