import React, { Component } from 'react'
import Contadores from './Contadores';
import Notificaciones from './Notificaciones';
import GBatery from '../../dashboard/overview/GBatery';
import GSignal from '../../dashboard/overview/GSignal';
import GTempE from '../../dashboard/overview/GTempE';
import GCota from './GCota';
import GProfundidad from './GProfundidad';
import GTemp from './GTemp';
import GPresion from './GPresion';

import GCota2 from './GCota2';
import GProfundidad2 from './GProfundidad2';
import GTemp2 from './GTemp2';
import GPresion2 from './GPresion2';

import GCota3 from './GCota3';
import GProfundidad3 from './GProfundidad3';
import GTemp3 from './GTemp3';
import GPresion3 from './GPresion3';

import GCota4 from './GCota4';
import GProfundidad4 from './GProfundidad4';
import GTemp4 from './GTemp4';
import GPresion4 from './GPresion4';




export class Page extends Component {

  render() {

    function renderi(num) {

      if (num.length > 0) {
        
        const styles1 = (num[2].ultimalectura.cota_1===null&&num[2].ultimalectura.profundidad_1===null) ? {display: 'none'}:{};
        const styles2 = (num[2].ultimalectura.cota_2===null&&num[2].ultimalectura.profundidad_2===null) ? {display: 'none'}:{};
        const styles3 = (num[2].ultimalectura.cota_3===null&&num[2].ultimalectura.profundidad_3===null) ? {display: 'none'}:{};
        const styles4 = (num[2].ultimalectura.cota_4===null&&num[2].ultimalectura.profundidad_4===null) ? {display: 'none'}:{};
       
        return (
          <div className="page">

            <header className="header">
              <nav className="navbar">
                <div className="container-fluid">
                  <div className="navbar-holder d-flex align-items-center justify-content-between">
                    <div className="navbar-header"><button id="toggle-btn" className="menu-btn btn-dark"><i className="icon-bars"> </i></button><span href="index.html" className="navbar-brand">
                      <div className="brand-text d-none d-md-inline-block"><span style={{ color: "#ccc" }}>{num[0].nombresitio[0].nombre} </span><strong className="text-primary"> {num[3].estatus}</strong></div></span></div>
                    <Notificaciones />
                  </div>
                </div>
              </nav>
            </header>

            <Contadores lecturas={num[2].ultimalectura} />

            <section className="dashboard-header " style={styles1}>              
              <div className="container-fluid">
              <h1 className="display-4">Piezometro 1</h1>
                <div className="row d-flex align-items-md-stretch">
                <GCota lecturas={num[1].lecturas} />
                  <GProfundidad lecturas={num[1].lecturas} />
                  <GTemp lecturas={num[1].lecturas} />
                  <GPresion lecturas={num[1].lecturas} />                 
                </div>
              </div>
            </section>

            <section className="dashboard-header " style={styles2}>
              <div className="container-fluid">
                <h1 className="display-4">Piezometro 2</h1>
                <div className="row d-flex align-items-md-stretch">
                <GCota2 lecturas={num[1].lecturas} />
                  <GProfundidad2 lecturas={num[1].lecturas} />
                  <GTemp2 lecturas={num[1].lecturas} />
                  <GPresion2 lecturas={num[1].lecturas} />                 
                </div>
              </div>
            </section>

            <section className="dashboard-header " style={styles3}>
              <div className="container-fluid">
                <h1 className="display-4">Piezometro 3</h1>
                <div className="row d-flex align-items-md-stretch">
                <GCota3 lecturas={num[1].lecturas} />
                  <GProfundidad3 lecturas={num[1].lecturas} />
                  <GTemp3 lecturas={num[1].lecturas} />
                  <GPresion3 lecturas={num[1].lecturas} />                 
                </div>
              </div>
            </section>

            <section className="dashboard-header " style={styles4}>
              <div className="container-fluid">
                <h1 className="display-4">Piezometro 4</h1>
                <div className="row d-flex align-items-md-stretch">
                <GCota4 lecturas={num[1].lecturas} />
                  <GProfundidad4 lecturas={num[1].lecturas} />
                  <GTemp4 lecturas={num[1].lecturas} />
                  <GPresion4 lecturas={num[1].lecturas} />                 
                </div>
              </div>
            </section>

            <section className="dashboard-header ">
              <div className="container-fluid">               
                <div className="row d-flex align-items-md-stretch">
                <GBatery lecturas={num[1].lecturas}  />
                  <GTempE lecturas={num[1].lecturas}  />
                  <GSignal lecturas={num[1].lecturas}  />                
                </div>
              </div>
            </section>
          

            <footer className="main-footer">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-sm-6">
                    <p>Instrutrónica -Liderti &copy; 2019</p>
                  </div>
                  <div className="col-sm-6 text-right">
                    <p>Version 1.0.0</p>
                  </div>
                </div>
              </div>
            </footer>
          </div>
        )
      } else {
        return (
          <div>Cargando</div>
        )
      }
    }

    return renderi(this.props.lecturas);
  }
}

export default Page

