import React, { Component } from "react";
import "../../dashboard/css/nav.css";
import "../../dashboard/css/font-awesome.css";
import "../../dashboard/css/fontastic.css";
import Navigation from "../Navigation";
import Notificaciones from "../../dashboard/overview/Notificaciones";
import BuscarSitio from "./BuscarSitio";
import axios from "axios";
import { ToastsContainer, ToastsStore } from "react-toasts";
import { withRouter } from "react-router-dom";
import https from "https";
const instance = axios.create({
  httpsAgent: new https.Agent({
    rejectUnauthorized: false
  })
});
export class Sitios extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lecturas: {
        _id: "-",
        idEquipo: "-",
        idMedidor: "-",
        nombre: "-",
        x: 0,
        y: 0,
        z: 0,
        lat: 0,
        long: 0,
        cantidad_equipos: "-",
        UND: "UND",
        min: 0,
        max: 0
      },

      sitioid: ""
    };
  }

  recibirDatosP = (childData) => {
    this.setState({ lecturas: childData });
  };
  onInputChange = (e) => {
    const todoestado = this.state.lecturas;
    if (e.target.name === "nombre") {
      todoestado.nombre = e.target.value;
      this.setState({ lecturas: todoestado });
    } else if (e.target.name === "x") {
      todoestado.x = e.target.value;
      this.setState({ lecturas: todoestado });
    } else if (e.target.name === "y") {
      todoestado.y = e.target.value;
      this.setState({ lecturas: todoestado });
    } else if (e.target.name === "z") {
      todoestado.z = e.target.value;
      this.setState({ lecturas: todoestado });
    } else if (e.target.name === "cantidad_equipos") {
      todoestado.cantidad_equipos = e.target.value;
      this.setState({ lecturas: todoestado });
    } else if (e.target.name === "UND") {
      todoestado.UND = e.target.value;
      this.setState({ lecturas: todoestado });
    } else if (e.target.name === "min") {
      todoestado.min = e.target.value;
      this.setState({ lecturas: todoestado });
    } else if (e.target.name === "max") {
      todoestado.max = e.target.value;
      this.setState({ lecturas: todoestado });
    } else if (e.target.name === "lat") {
      todoestado.lat = e.target.value;
      this.setState({ lecturas: todoestado });
    } else if (e.target.name === "long") {
      todoestado.long = e.target.value;
      this.setState({ lecturas: todoestado });
    }
  };

  actualizarDatos = async (e) => {
    const updatedNote = {
      nombre: this.state.lecturas.nombre,
      x: this.state.lecturas.x,
      y: this.state.lecturas.y,
      z: this.state.lecturas.z,
      lat: this.state.lecturas.lat,
      long: this.state.lecturas.long,
      cantidad_equipos: this.state.lecturas.cantidad_equipos,
      UND: this.state.lecturas.UND,
      min: this.state.lecturas.min,
      max: this.state.lecturas.max
    };
    const res = await instance.put(
      "https://api.instrutronica-iot.com/api/pruebas/sitios/" +
        this.state.lecturas._id,
      updatedNote
    );
    console.log(res.data);
    if (res.data === "Sitio Actualizado") {
      ToastsStore.success("sitio Actualizado");
    } else {
      ToastsStore.error("Fallo Actualización");
    }
  };
  async componentDidMount() {
    this.interval = setInterval(async () => {
      const tip = JSON.parse(localStorage.getItem("tipo"));
      if (tip.tipo !== "admin") {
        this.props.history.push("/");
      }
    }, 1000);
  }
  render() {
    return (
      <div>
        <Navigation></Navigation>
        <div className="page">
          <header className="header">
            <nav className="navbar">
              <div className="container-fluid">
                <div className="navbar-holder d-flex align-items-center justify-content-between">
                  <div className="navbar-header">
                    <button id="toggle-btn" className="menu-btn btn-dark">
                      <i className="icon-bars"> </i>
                    </button>
                    <span href="index.html" className="navbar-brand">
                      <div className="brand-text d-none d-md-inline-block">
                        <span style={{ color: "#ccc" }}>{} </span>
                        <strong className="text-primary"> {}</strong>
                      </div>
                    </span>
                  </div>
                  <Notificaciones></Notificaciones>
                </div>
              </div>
            </nav>
          </header>
          <div className="breadcrumb-holder">
            <div className="container-fluid">
              <ul className="breadcrumb">
                <li className="breadcrumb-item">Vista General</li>
                <li className="breadcrumb-item active">Información </li>
              </ul>
            </div>
          </div>

          <section className="dashboard-header section-padding">
            <div className="container-fluid">
              <div className="row d-flex align-items-md-stretch">
                <div className="col-lg-12 col-md-12 flex-lg-last flex-md-first align-self-baseline">
                  <BuscarSitio recibirDatosP={this.recibirDatosP}></BuscarSitio>
                </div>
                <div className="col-12 flex-lg-last flex-md-first align-self-baseline">
                  <p>
                    Selecciona un sitio en la lista, dale clic en cargar sitio y
                    abajo modifica sus datos, presiona Actualizar para guardar
                    los cambios.
                  </p>{" "}
                  <strong>Los Cambios NO son reversibles.</strong>
                </div>

                <div className="col-lg-12 col-12 ">
                  <form>
                    <div className="form-group ">
                      <label htmlFor="nombre">Nombre del Sitio</label>
                      <input
                        type="text"
                        name="nombre"
                        className="form-control"
                        onChange={this.onInputChange}
                        value={this.state.lecturas.nombre}
                      ></input>

                      <label htmlFor="x">X:</label>
                      <input
                        name="x"
                        className="form-control"
                        type="text"
                        onChange={this.onInputChange}
                        value={this.state.lecturas.x}
                      ></input>
                      <label htmlFor="y">Y:</label>
                      <input
                        name="y"
                        className="form-control"
                        type="text"
                        onChange={this.onInputChange}
                        value={this.state.lecturas.y}
                      ></input>
                      <label htmlFor="z">Z:</label>
                      <input
                        name="z"
                        className="form-control"
                        type="text"
                        onChange={this.onInputChange}
                        value={this.state.lecturas.z}
                      ></input>

                      <label htmlFor="lat">Latitud:</label>
                      <input
                        name="lat"
                        className="form-control"
                        type="text"
                        onChange={this.onInputChange}
                        value={this.state.lecturas.lat}
                      ></input>
                      <label htmlFor="long">Longitud:</label>
                      <input
                        name="long"
                        className="form-control"
                        type="text"
                        onChange={this.onInputChange}
                        value={this.state.lecturas.long}
                      ></input>

                      <label htmlFor="min">Min:</label>
                      <input
                        name="min"
                        className="form-control"
                        type="text"
                        onChange={this.onInputChange}
                        value={this.state.lecturas.min}
                      ></input>
                      <label htmlFor="max">Max:</label>
                      <input
                        name="max"
                        className="form-control"
                        type="text"
                        onChange={this.onInputChange}
                        value={this.state.lecturas.max}
                      ></input>

                      <label htmlFor="cantidad_equipos">
                        Cantidad de Equipos:
                      </label>
                      <input
                        name="cantidad_equipos"
                        onChange={this.onInputChange}
                        className="form-control"
                        type="text"
                        value={this.state.lecturas.cantidad_equipos}
                      ></input>
                      <label htmlFor="UND">UND:</label>
                      <input
                        name="UND"
                        className="form-control"
                        onChange={this.onInputChange}
                        type="text"
                        value={this.state.lecturas.UND}
                      ></input>
                    </div>
                  </form>
                </div>
                <div className="col-12 flex-lg-last flex-md-first align-self-baseline">
                  <button
                    onClick={this.actualizarDatos}
                    className="btn btn-danger"
                  >
                    Actualizar
                  </button>
                  <ToastsContainer store={ToastsStore} />
                </div>
              </div>
            </div>
          </section>

          <footer className="main-footer">
            <div className="container-fluid">
              <div className="row">
                <div className="col-sm-6">
                  <p>Instrutrónica -Liderti &copy; 2019</p>
                </div>
                <div className="col-sm-6 text-right">
                  <p>Version 1.0.0</p>
                </div>
              </div>
            </div>
          </footer>
        </div>
      </div>
    );
  }
}

export default withRouter(Sitios);
