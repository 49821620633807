import React, { Component } from 'react'
import Contadores from './Contadores';
import Notificaciones from './Notificaciones';
import GCaudal1 from './GCaudal1';
import GCaudal2 from './GCaudal2';
import GVolumen1 from './GVolumen1';
import GVolumen2 from './GVolumen2';
import GBatery from '../../dashboard/overview/GBatery';
import GSignal from '../../dashboard/overview/GSignal';
import GTempE from '../../dashboard/overview/GTempE';
import VolSemanal1 from './VolSemanal1';
import VolMensual1 from './VolMensual1';
import VolAcumulado1 from './VolAcumulado1';
import VolSemanal2 from './VolSemanal2';
import VolMensual2 from './VolMensual2';
import VolAcumulado2 from './VolAcumulado2';

export class Page extends Component {

  render() {

    function renderi(num) {
      

      if (num.length > 0) {
        const styles1 = (num[2].ultimalectura.caudal_1===null&&num[2].ultimalectura.volumen_1===null) ? {display: 'none'}:{};
        const styles2 = (num[2].ultimalectura.caudal_2===null&&num[2].ultimalectura.volumen_2===null) ? {display: 'none'}:{};
        return (
          <div className="page">

            <header className="header">
              <nav className="navbar">
                <div className="container-fluid">
                  <div className="navbar-holder d-flex align-items-center justify-content-between">
                    <div className="navbar-header"><button id="toggle-btn" className="menu-btn btn-dark"><i className="icon-bars"> </i></button><span href="index.html" className="navbar-brand">
                      <div className="brand-text d-none d-md-inline-block"><span style={{ color: "#ccc" }}>{num[0].nombresitio[0].nombre} </span><strong className="text-primary"> {num[9].estatus}</strong></div></span></div>
                    <Notificaciones />
                  </div>
                </div>
              </nav>
            </header>

            <Contadores lecturas={num[2].ultimalectura} />

            <section className="dashboard-header " style={styles1}>
              <div className="container-fluid">
                <div className="row d-flex align-items-md-stretch">

                  <GCaudal1 lecturas={num[1].lecturas} />

                  <GVolumen1 lecturas={num[1].lecturas} />
                 
                </div>
              </div>
            </section>
            <section className="dashboard-header " style={styles2}>
              <div className="container-fluid">
                <div className="row d-flex align-items-md-stretch">

                  <GCaudal2 lecturas={num[1].lecturas} />

                  <GVolumen2 lecturas={num[1].lecturas} />
                  
                </div>
              </div>
            </section>
            <section className="dashboard-header ">
              <div className="container-fluid">
                <div className="row d-flex align-items-md-stretch">

                  <GBatery lecturas={num[1].lecturas}  />
                  <GTempE lecturas={num[1].lecturas}  />
                  <GSignal lecturas={num[1].lecturas}  />
                </div>
              </div>
            </section>


            <section className="statistics" style={styles1}>
              <div className="container-fluid">
                <div className="row d-flex">
                  <VolAcumulado1 voldia1={num[7].voldia1} />
                  <VolSemanal1 volsem1={num[5].volsem1}  />
                  <VolMensual1 volmes1={num[3].volmes1}  />


                </div>
              </div>
            </section>
            <section className="statistics" style={styles2}>
              <div className="container-fluid">
                <div className="row d-flex">
                  

                  <VolAcumulado2 voldia2={num[8].voldia2} />
                  <VolSemanal2 volsem2={num[6].volsem2}  />
                  <VolMensual2 volmes2={num[4].volmes2}  />

                </div>
              </div>
            </section>

            <footer className="main-footer">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-sm-6">
                    <p>Instrutrónica -Liderti &copy; 2019</p>
                  </div>
                  <div className="col-sm-6 text-right">
                    <p>Version 5.0.1</p>
                  </div>
                </div>
              </div>
            </footer>
          </div>
        )
      } else {
        return (
          <div>Cargando</div>
        )
      }
    }

    return renderi(this.props.lecturas);
  }
}

export default Page

