import React, { Component } from 'react';
import Navigation from '../Navigation';
import Notificaciones from '../overview/Notificaciones';
import axios from 'axios';
import { CSVLink } from 'react-csv';
import { withRouter } from 'react-router-dom';
/*function convertirfecha(fecha) {
    if (fecha !== '') {
        //console.log(this.state.sitios[i].fechalectura);
        var fec = new Date("" + fecha + "");
        var hora = fec.toLocaleTimeString();
        var dia = fec.toLocaleDateString();
        return dia + ' ' + hora;
    } else {
        return '-';
    }
};*/


export class Lecturas extends Component {
    constructor(props) {
        super(props);
        this.state = {
            lecturas: [],
            fecha1:'',
            fecha2:'',
            sitio:''
        };
    }
    
   
    
    fecha1(event){
        this.setState({fecha1:event.target.value});
        
    };
    fecha2(event){
        this.setState({fecha2:event.target.value});
    };
    async cargarlecturas(){        
        const res = await axios.get('https://api.instrutronica-iot.com/api/pruebas/lecturas7/fechas/' + this.state.sitio+'/'+this.state.fecha1+'/'+this.state.fecha2);
        this.setState({ lecturas: res.data});             
    };
    
   

    async componentDidMount() {
        var query = window.location.href;
        var sit = query.split("/");
        const res = await axios.get('https://api.instrutronica-iot.com/api/pruebas/lecturas7/hoy/' + sit[5]);
        this.setState({ lecturas: res.data });
        this.setState({sitio: sit[5]});

        this.interval =  setInterval(async() =>{
            if(localStorage.getItem('pruebas_app')==null){
                this.props.history.push("/");
            }
        },1000);

    }

    render() {
        let count=1;
        return (
            <div>
                <Navigation></Navigation>
                <div className="page">

                    <header className="header">
                        <nav className="navbar">
                            <div className="container-fluid">
                                <div className="navbar-holder d-flex align-items-center justify-content-between">
                                    <div className="navbar-header"><button id="toggle-btn" className="menu-btn btn-dark"><i className="icon-bars"> </i></button><span href="index.html" className="navbar-brand">
                                        <div className="brand-text d-none d-md-inline-block"><span style={{ color: "#ccc" }}>{} </span><strong className="text-primary"> {}</strong></div></span></div>
                                    <Notificaciones></Notificaciones>
                                </div>
                            </div>
                        </nav>
                    </header>
                    <div className="breadcrumb-holder">
                        <div className="container-fluid">
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item">Vista General</li>
                                <li className="breadcrumb-item active">Lecturas       </li>
                            </ul>
                        </div>
                    </div>

                    <section>
                        <div className="container-fluid">
                            <header>
                                <h1 className="h3 display">Lecturas            </h1>
                                <p>Para descargar un reporte primero cargue las lecturas y después presione el botón Descargar CSV</p>
                            </header>
                            <div className="row">
                                <div className="col-lg-12">

                                    <div className="input-group">
                                        <input id="fecha1" onChange={this.fecha1.bind(this)}  type="date" className="form-control" placeholder="Fecha Inicial" aria-label="Fecha Inicial" aria-describedby="button-addon4"></input>
                                        <input id="fecha2" onChange={this.fecha2.bind(this)} type="date" className="form-control" placeholder="Fecha Final" aria-label="Fecha fianl" aria-describedby="button-addon4"></input>
                                        <div className="input-group-append" id="button-addon4">
                                            <button className="btn  btn-success" type="button" onClick={this.cargarlecturas.bind(this)}>Cargar Lecturas</button>
                                            <CSVLink className="btn btn-info"
                                                data={this.state.lecturas}
                                                asyncOnClick={true}
                                                filename={"reporte.csv"}                                                
                                                >
                                                Descargar CSV
                                                </CSVLink>
                                        </div>
                                    </div>




                                </div>
                                <div className="col-lg-12">
                                    <div className="card">
                                        <div className="card-header">
                                            <h4>Lecturas del dia de hoy</h4>
                                        </div>
                                        <div className="card-body">
                                            <div className="table-responsive">
                                                <table className="table table-striped table-hover">
                                                    <thead>
                                                        <tr>
                                                            <th>#</th>
                                                            <th>Fecha</th>
                                                            <th>Arranque</th>
                                                            <th>Caudal</th>
                                                            <th>Volumen</th>
                                                            <th>Nivel</th>
                                                            <th>Presión</th>
                                                            <th>Temperatura</th>
                                                            <th>Energía Real</th>
                                                            <th>Potencia Activa</th>
                                                            <th>Potencia Reactiva</th>
                                                            <th>Voltaje L1-L2</th>
                                                            <th>Voltaje L2-L3</th>
                                                            <th>Voltaje L1-L3</th>
                                                            <th>Señal</th>
                                                            <th>Batería</th>
                                                            
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            
                                                            this.state.lecturas.map(function (lectura) {
                                                                return <tr key={lectura._id}>
                                                                    <th scope="row">{count++}</th> 
                                                                    <td>{(!lectura.fechalec)?(lectura.fechalectura):(lectura.fechalec)}</td>                                                                  
                                                                    <td>{(lectura.io1===null) ? 'null':((lectura.io1===true)?'Encendido':'Apagado')}</td>
                                                                    <td>{(lectura.d1===null) ? 'null':(lectura.d1)}</td>
                                                                    <td>{(lectura.d2===null) ? 'null':(lectura.d2)}</td>
                                                                    <td>{(lectura.d3===null) ? 'null':(lectura.d3)}</td>
                                                                    <td>{(lectura.d4===null) ? 'null':(lectura.d4)}</td>
                                                                    <td>{(lectura.d5===null) ? 'null':(lectura.d5)}</td>
                                                                    <td>{(lectura.d6===null) ? 'null':(lectura.d6)}</td>
                                                                    <td>{(lectura.d7===null) ? 'null':(lectura.d7)}</td>
                                                                    <td>{(lectura.d8===null) ? 'null':(lectura.d8)}</td>
                                                                    <td>{(lectura.d9===null) ? 'null':(lectura.d9)}</td>
                                                                    <td>{(lectura.d10===null) ? 'null':(lectura.d10)}</td>
                                                                    <td>{(lectura.d11===null) ? 'null':(lectura.d11)}</td>
                                                                    <td>{(lectura.senal===null) ? 'null':(lectura.senal)}</td>
                                                                    <td>{(lectura.bateria===null) ? 'null':(lectura.bateria)}</td>
                                                                    
                                                                    
                                                                </tr>;
                                                            })
                                                        }


                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <footer className="main-footer">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-sm-6">
                                    <p>Instrutrónica -Liderti &copy; 2019</p>
                                </div>
                                <div className="col-sm-6 text-right">
                                    <p>Version 1.0.0</p>
                                </div>
                            </div>
                        </div>
                    </footer>
                </div>
            </div>
        )
    }
}

export default withRouter(Lecturas)
