import React, { Component } from 'react';
import './css/nav.css';
import './css/font-awesome.css';
import './css/fontastic.css';
import axios from 'axios';
import Navigation from './Navigation';
import Page from './overview/Page';
import { withRouter } from 'react-router-dom';

export class Dashboard extends Component {

    constructor(props) {
        super(props);
            this.state={
                datos:[ {
                    "nombresitio": [
                        {
                            "_id": "5de77233e4c95d1640c0c0a5",
                            "nombre": "Actualizando"
                        }
                    ]
                },
                {
                    "lecturas": [
                        {
                            "_id": "0",
                            "cota_1": null,
                            "temperatura_1": null,
                            "profundidad_1": null,
                            "presion_1": null,
                            "cota_2": null,
                            "temperatura_2": null,
                            "profundidad_2": null,
                            "presion_2": null,
                            "cota_3": null,
                            "temperatura_3": null,
                            "profundidad_3": null,
                            "presion_3": null,
                            "cota_4": null,
                            "temperatura_4": null,
                            "profundidad_4": null,
                            "presion_4": null,
                            "fechalec": "null",
                            "fechalectura": "2019-01-01T00:00:00.000Z",
                            "estatus": "No hay Datos",
                            "senal": null,
                            "temp_equipo": null,
                            "bateria": null
                        }
                    ]
                },
                {
                    "ultimalectura": [
                        {
                            "_id": "0",
                            "cota_1": null,
                            "temperatura_1": null,
                            "profundidad_1": null,
                            "presion_1": null,
                            "cota_2": null,
                            "temperatura_2": null,
                            "profundidad_2": null,
                            "presion_2": null,
                            "cota_3": null,
                            "temperatura_3": null,
                            "profundidad_3": null,
                            "presion_3": null,
                            "cota_4": null,
                            "temperatura_4": null,
                            "profundidad_4": null,
                            "presion_4": null,
                            "fechalec": "null",
                            "fechalectura": "2019-01-01T00:00:00.000Z",
                            "estatus": "No hay Datos",
                            "senal": null,
                            "temp_equipo": null,
                            "bateria": null
                        }
                    ]
                },
                {
                    "estatus": "Actualizando"
                }],
                contador:1,
                sitio:''      
            };
    }

    async componentDidMount(){
        var query = window.location.href;
        var sit=query.split("/");
        const res = await axios.get('https://api.instrutronica-iot.com/api/pruebas/overview4/'+sit[5]);
        this.setState({datos: res.data},()=>{this.setState({contador: this.state.contador++},()=>{this.render();}) });
        this.setState({sitio:sit[5]});

        this.interval =  setInterval(async() =>{
            var d = new Date();
            var n = d.getMinutes(); 
            var seg= d.getSeconds(); 
            if(localStorage.getItem('pruebas_app')==null){
                this.props.history.push("/");
            }        
            //console.log(seg);
            if (n===1||n===16||n===31||n===46){
                if (seg===0){
                    const res = await axios.get('https://api.instrutronica-iot.com/api/pruebas/overview4/'+sit[5]);
                    this.setState({datos: res.data},()=>{this.setState({contador: this.state.contador++},()=>{this.render();}) });
                }
            }
        },1000);
    }
    
    componentWillUnmount() {
        clearInterval(this.interval);
       
    }

    render() {   
        //console.log(this.state.datos);     
            return (
                <div>
                    <Navigation/>
                  <Page  lecturas={this.state.datos} contador={this.state.contador}/>
                </div>
            )
    }
}
/*nombre={this.state.datos[0].nombresitio[0].nombre} mes={this.state.datos[2].mes} semana={this.state.datos[3].semana}  lecturas={this.state.datos[1].lecturas} ultimalectura={this.state.ultimalectura} */
export default withRouter(Dashboard)
