import React, { Component } from 'react'

export class VolAcumulado extends Component {
    render() {
        return (
            <div className="col-lg-4">
              
              <div className="card income text-center">
                <div className="icon"><i className="icon-line-chart"></i></div>
                <div className="number">{this.props.voldia}</div><strong className="text-primary">Volumen Del Día M3</strong>
                <p>Volumen consumido del día de hoy.</p>
              </div>
            </div>
        )
    }
}

export default VolAcumulado
