import React, { Component } from "react";
import CuadroAlertas from "./cuadroalertas2";
import axios from "axios";
import L from "leaflet";
/*import 'leaflet/dist/leaflet.css';*/
import styled from "styled-components";
import iconVerde from "./img/gotaverde.png";
import iconRojo from "./img/gotaroja.gif";
import iconVerdeP from "./img/gotaverdep.gif";
import iconAmarillo from "./img/gotaamarilla.gif";
import { withRouter } from "react-router-dom";
import https from "https";
const instance = axios.create({
  httpsAgent: new https.Agent({
    rejectUnauthorized: false
  })
});
const Wrapper = styled.div`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
`;

class CargarPuntos2 extends Component {
  state = {
    sitios: [],
    contador: 1
  };

  async componentDidMount() {
    //pedir al servidor
    const res = await instance.get(
      "https://api.instrutronica-iot.com/api/pruebas/mapa"
    );
    this.setState({ sitios: res.data });
    //console.log(this.state.sitios);
    // NOTE: Layer is created here!
    var marker;
    var orografico = L.tileLayer(
        "https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png",
        {
          attribution:
            'Map data: &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, <a href="http://viewfinderpanoramas.org">SRTM</a> | Map style: &copy; <a href="https://opentopomap.org">OpenTopoMap</a> (<a href="https://creativecommons.org/licenses/by-sa/3.0/">CC-BY-SA</a>)',
          detectRetina: true,
          maxZoom: 20,
          maxNativeZoom: 17
        }
      ),
      satelite = L.tileLayer(
        "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}",
        {
          attribution:
            "Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community",
          detectRetina: true,
          maxZoom: 20,
          maxNativeZoom: 17
        }
      );
    this.map = L.map("map", {
      center: [19.432393, -99.153847],
      zoom: 12,
      zoomControl: true,
      layers: [satelite]
    });

    var markersLayer = L.layerGroup().addTo(this.map);
    var markersLayer_2 = L.layerGroup().addTo(this.map);
    var markersLayer_3 = L.layerGroup().addTo(this.map);
    var GotaIcono = L.Icon.extend({
      options: {
        iconSize: [16, 16],
        popupAnchor: [0, 0],
        iconAnchor: [0, 0]
      }
    });
    var gotaverde = new GotaIcono({ iconUrl: iconVerde }),
      gotarojo = new GotaIcono({ iconUrl: iconRojo }),
      gotaverdepar = new GotaIcono({ iconUrl: iconVerdeP }),
      gotaamarillo = new GotaIcono({ iconUrl: iconAmarillo });

    var customOptions = {
      maxWidth: "500",
      className: "custom"
    };

    //funcion para armar las gotas y sus respectivos iconos
    var customPopup = "";
    var ic;
    for (var i in this.state.sitios) {
      var latlng = L.latLng({
        lat: this.state.sitios[i].lat,
        lng: this.state.sitios[i].long
      });

      // console.log( this.state.sitios[i]);

      if (this.state.sitios[i].tipo === 1) {
        customPopup =
          " <div className='container'><div className='row'><div className='col-12 table-responsive-sm'><table className='table table-striped table-sm table-dark'><thead><tr><th colSpan='4' scope='col' className='text-center'>" +
          this.state.sitios[i].nombre +
          "</th></tr></thead><tbody><tr><th scope='row'>Caudal:</th><td>" +
          this.state.sitios[i].caudal +
          " L/S</td><th scope='row'>Volumen:</th><td>" +
          this.state.sitios[i].volumen +
          " M3</td></tr><tr><th colSpan='2' scope='row'>Fecha de Lectura</th><td colSpan='2'>" +
          this.state.sitios[i].fechalec +
          "</td></tr><tr><th colSpan='4' ><a className='btn btn-primary btn-sm btn-block' target='_blank' rel='noopener noreferrer' href='/dashboard/" +
          this.state.sitios[i].tipo +
          "/" +
          this.state.sitios[i].idSitio +
          "'>Ir Al Sitio</a></th></tr></tbody></table></div></div></div>";

        if (this.state.sitios[i].estatus === "normal") {
          ic = gotaverde;
        } else if (
          this.state.sitios[i].estatus === "mayor" ||
          this.state.sitios[i].estatus === "cero"
        ) {
          ic = gotarojo;
        } else if (this.state.sitios[i].estatus === "menor") {
          ic = gotaamarillo;
        } else if (this.state.sitios[i].estatus === "redint") {
          ic = gotaverdepar;
        }

        marker = L.marker(latlng, { icon: ic }).bindPopup(
          customPopup,
          customOptions
        );
        markersLayer.addLayer(marker);
      } else if (this.state.sitios[i].tipo === 7) {
        var customPopup0 =
          " <div className='container'><div className='row'><div className='col-12 table-responsive-sm'><table className='table table-striped table-sm table-dark'><thead><tr><th colSpan='4' scope='col' className='text-center'>" +
          this.state.sitios[i].nombre +
          "</th></tr></thead><tbody><tr><th scope='row'>Caudal:</th><td>" +
          this.state.sitios[i].caudal +
          " L/S</td><th scope='row'>Volumen:</th><td>" +
          this.state.sitios[i].volumen +
          " M3</td>";
        if (this.state.sitios[i].io1 === true) {
          var customPopup1 =
            "<th scope='row'>Arranque:</th><td> Encendido</td>";
        } else {
          var customPopup1 = "<th scope='row'>Arranque:</th><td> Apagado</td>";
        }
        var customPopup2 =
          "</tr><tr><th colSpan='2' scope='row'>Fecha de Lectura</th><td colSpan='2'>" +
          this.state.sitios[i].fechalec +
          "</td></tr><tr><th colSpan='4' ><a className='btn btn-primary btn-sm btn-block' target='_blank' rel='noopener noreferrer' href='/dashboard/" +
          this.state.sitios[i].tipo +
          "/" +
          this.state.sitios[i].idSitio +
          "'>Ir Al Sitio</a></th></tr></tbody></table></div></div></div>";
        customPopup = customPopup0.concat(customPopup1, customPopup2);
        if (this.state.sitios[i].estatus === "normal") {
          ic = gotaverde;
        } else if (
          this.state.sitios[i].estatus === "mayor" ||
          this.state.sitios[i].estatus === "cero"
        ) {
          ic = gotarojo;
        } else if (this.state.sitios[i].estatus === "menor") {
          ic = gotaamarillo;
        } else if (this.state.sitios[i].estatus === "redint") {
          ic = gotaverdepar;
        }

        marker = L.marker(latlng, { icon: ic }).bindPopup(
          customPopup,
          customOptions
        );
        markersLayer_3.addLayer(marker);
      } else if (this.state.sitios[i].tipo === 2) {
        customPopup =
          " <div className='container'><div className='row'><div className='col-12 table-responsive-sm'><table className='table table-striped table-sm table-dark'><thead><tr><th colSpan='4' scope='col' className='text-center'>" +
          this.state.sitios[i].nombre +
          "</th></tr></thead><tbody><tr><th scope='row'>Caudal:</th><td>" +
          this.state.sitios[i].caudal1 +
          " L/S</td><th scope='row'>Volumen:</th><td>" +
          this.state.sitios[i].volumen1 +
          " M3</td></tr><tr><th scope='row'>Caudal:</th><td>" +
          this.state.sitios[i].caudal2 +
          " L/S</td><th scope='row'>Volumen:</th><td>" +
          this.state.sitios[i].volumen2 +
          " M3</td></tr><tr><th colSpan='2' scope='row'>Fecha de Lectura</th><td colSpan='2'>" +
          this.state.sitios[i].fechalec +
          "</td></tr><tr><th colSpan='4' ><a className='btn btn-primary btn-sm btn-block' target='_blank' rel='noopener noreferrer' href='/dashboard/" +
          this.state.sitios[i].tipo +
          "/" +
          this.state.sitios[i].idSitio +
          "'>Ir Al Sitio</a></th></tr></tbody></table></div></div></div>";

        if (this.state.sitios[i].estatus === "normal") {
          ic = gotaverde;
        } else if (
          this.state.sitios[i].estatus === "mayor" ||
          this.state.sitios[i].estatus === "cero"
        ) {
          ic = gotarojo;
        } else if (this.state.sitios[i].estatus === "menor") {
          ic = gotaamarillo;
        } else if (this.state.sitios[i].estatus === "redint") {
          ic = gotaverdepar;
        }

        marker = L.marker(latlng, { icon: ic }).bindPopup(
          customPopup,
          customOptions
        );
        markersLayer.addLayer(marker);
      } else if (this.state.sitios[i].tipo === 3) {
        customPopup =
          " <div className='container'><div className='row'><div className='col-12 table-responsive-sm'><table className='table table-striped table-sm table-dark'><thead><tr><th colSpan='4' scope='col' className='text-center'>" +
          this.state.sitios[i].nombre +
          "</th></tr></thead><tbody><tr><th scope='row'>Cota:</th><td>" +
          this.state.sitios[i].cota +
          " msnm</td><th scope='row'>Profundidad:</th><td>" +
          this.state.sitios[i].profundidad +
          " mbs</td></tr><tr><th scope='row'>Temperatura:</th><td>" +
          this.state.sitios[i].temperatura +
          " °C</td><th scope='row'>Presión:</th><td>" +
          this.state.sitios[i].presion +
          " cm H2O</td></tr><tr><th colSpan='2' scope='row'>Fecha de Lectura</th><td colSpan='2'>" +
          this.state.sitios[i].fechalec +
          "</td></tr><tr><th colSpan='4' ><a className='btn btn-primary btn-sm btn-block' target='_blank' rel='noopener noreferrer' href='/dashboard/" +
          this.state.sitios[i].tipo +
          "/" +
          this.state.sitios[i].idSitio +
          "'>Ir Al Sitio</a></th></tr></tbody></table></div></div></div>";

        if (this.state.sitios[i].estatus === "normal") {
          ic = gotaverde;
        } else if (
          this.state.sitios[i].estatus === "mayor" ||
          this.state.sitios[i].estatus === "cero"
        ) {
          ic = gotarojo;
        } else if (this.state.sitios[i].estatus === "menor") {
          ic = gotaamarillo;
        } else if (this.state.sitios[i].estatus === "redint") {
          ic = gotaverdepar;
        }

        marker = L.marker(latlng, { icon: ic }).bindPopup(
          customPopup,
          customOptions
        );
        markersLayer_2.addLayer(marker);
      } else if (this.state.sitios[i].tipo === 4) {
        customPopup =
          " <div className='container'><div className='row'><div className='col-12 table-responsive-sm'><table className='table table-striped table-sm table-dark'><thead><tr><th colSpan='4' scope='col' className='text-center'>" +
          this.state.sitios[i].nombre +
          "</th></tr></thead><tbody><tr><th scope='row'>Cota:</th><td>" +
          this.state.sitios[i].cota1 +
          " msnm</td><th scope='row'>Profundidad:</th><td>" +
          this.state.sitios[i].profundidad1 +
          " mbs</td></tr><tr><th scope='row'>Temperatura:</th><td>" +
          this.state.sitios[i].temperatura1 +
          " °C</td><th scope='row'>Presión:</th><td>" +
          this.state.sitios[i].presion1 +
          " cm H2O</td></tr><tr><th scope='row'>Cota:</th><td>" +
          this.state.sitios[i].cota2 +
          " msnm</td><th scope='row'>Profundidad:</th><td>" +
          this.state.sitios[i].profundidad2 +
          " mbs</td></tr><tr><th scope='row'>Temperatura:</th><td>" +
          this.state.sitios[i].temperatura2 +
          " °C</td><th scope='row'>Presión:</th><td>" +
          this.state.sitios[i].presion2 +
          " cm H2O</td></tr><tr><th scope='row'>Cota:</th><td>" +
          this.state.sitios[i].cota3 +
          " msnm</td><th scope='row'>Profundidad:</th><td>" +
          this.state.sitios[i].profundidad3 +
          " mbs</td></tr><tr><th scope='row'>Temperatura:</th><td>" +
          this.state.sitios[i].temperatura3 +
          " °C</td><th scope='row'>Presión:</th><td>" +
          this.state.sitios[i].presion3 +
          " cm H2O</td></tr><tr><th scope='row'>Cota:</th><td>" +
          this.state.sitios[i].cota4 +
          " msnm</td><th scope='row'>Profundidad:</th><td>" +
          this.state.sitios[i].profundidad4 +
          " mbs</td></tr><tr><th scope='row'>Temperatura:</th><td>" +
          this.state.sitios[i].temperatura4 +
          " °C</td><th scope='row'>Presión:</th><td>" +
          this.state.sitios[i].presion4 +
          " cm H2O</td></tr><tr><th colSpan='2' scope='row'>Fecha de Lectura</th><td colSpan='2'>" +
          this.state.sitios[i].fechalec +
          "</td></tr><tr><th colSpan='4' ><a className='btn btn-primary btn-sm btn-block' target='_blank' rel='noopener noreferrer' href='/dashboard/" +
          this.state.sitios[i].tipo +
          "/" +
          this.state.sitios[i].idSitio +
          "'>Ir Al Sitio</a></th></tr></tbody></table></div></div></div>";

        if (this.state.sitios[i].estatus === "normal") {
          ic = gotaverde;
        } else if (
          this.state.sitios[i].estatus === "mayor" ||
          this.state.sitios[i].estatus === "cero"
        ) {
          ic = gotarojo;
        } else if (this.state.sitios[i].estatus === "menor") {
          ic = gotaamarillo;
        } else if (this.state.sitios[i].estatus === "redint") {
          ic = gotaverdepar;
        }

        marker = L.marker(latlng, { icon: ic }).bindPopup(
          customPopup,
          customOptions
        );
        markersLayer_2.addLayer(marker);
      } else if (this.state.sitios[i].tipo === 5) {
        customPopup =
          " <div className='container'><div className='row'><div className='col-12 table-responsive-sm'><table className='table table-striped table-sm table-dark'><thead><tr><th colSpan='4' scope='col' className='text-center'>" +
          this.state.sitios[i].nombre +
          "</th></tr></thead><tbody><tr><th scope='row'>Caudal:</th><td>" +
          this.state.sitios[i].caudal +
          " L/S</td><th scope='row'>Volumen:</th><td>" +
          this.state.sitios[i].volumen +
          " M3</td></tr><tr><tr><th scope='row'>Voltaje 1:</th><td>" +
          this.state.sitios[i].voltaje_1 +
          " V</td><th scope='row'>Corriente 1:</th><td>" +
          this.state.sitios[i].corriente_1 +
          " M3</td></tr><tr><th scope='row'>Potencia 1:</th><td>" +
          this.state.sitios[i].potencia_1 +
          " V</td><th scope='row'>Energia 1:</th><td>" +
          this.state.sitios[i].energia_1 +
          " V</td></tr><tr><th colSpan='2' scope='row'>Fecha de Lectura</th><td colSpan='2'>" +
          this.state.sitios[i].fechalec +
          "</td></tr><tr><th colSpan='4' ><a className='btn btn-primary btn-sm btn-block' target='_blank' rel='noopener noreferrer' href='/dashboard/" +
          this.state.sitios[i].tipo +
          "/" +
          this.state.sitios[i].idSitio +
          "'>Ir Al Sitio</a></th></tr></tbody></table></div></div></div>";

        if (this.state.sitios[i].estatus === "normal") {
          ic = gotaverde;
        } else if (
          this.state.sitios[i].estatus === "mayor" ||
          this.state.sitios[i].estatus === "cero"
        ) {
          ic = gotarojo;
        } else if (this.state.sitios[i].estatus === "menor") {
          ic = gotaamarillo;
        } else if (this.state.sitios[i].estatus === "redint") {
          ic = gotaverdepar;
        }

        marker = L.marker(latlng, { icon: ic }).bindPopup(
          customPopup,
          customOptions
        );
        markersLayer_3.addLayer(marker);
      } else if (this.state.sitios[i].tipo === 6) {
        customPopup =
          " <div className='container'><div className='row'><div className='col-12 table-responsive-sm'><table className='table table-striped table-sm table-dark'><thead><tr><th colSpan='4' scope='col' className='text-center'>" +
          this.state.sitios[i].nombre +
          "</th></tr></thead><tbody><tr><th scope='row'>Caudal:</th><td>" +
          this.state.sitios[i].caudal +
          " L/S</td><th scope='row'>Volumen:</th><td>" +
          this.state.sitios[i].volumen +
          " M3</td></tr><tr><th scope='row'>Temperatura:</th><td>" +
          this.state.sitios[i].temperatura +
          " C°</td><th scope='row'>Elevación:</th><td>" +
          this.state.sitios[i].elevacion +
          " </td></tr><tr><th scope='row'>Conductividad:</th><td>" +
          this.state.sitios[i].conductividad +
          " </td></tr><tr><th colSpan='2' scope='row'>Fecha de Lectura</th><td colSpan='2'>" +
          this.state.sitios[i].fechalec +
          "</td></tr><tr><th colSpan='4' ><a className='btn btn-primary btn-sm btn-block' target='_blank' rel='noopener noreferrer' href='/dashboard/" +
          this.state.sitios[i].tipo +
          "/" +
          this.state.sitios[i].idSitio +
          "'>Ir Al Sitio</a></th></tr></tbody></table></div></div></div>";

        if (this.state.sitios[i].estatus === "normal") {
          ic = gotaverde;
        } else if (
          this.state.sitios[i].estatus === "mayor" ||
          this.state.sitios[i].estatus === "cero"
        ) {
          ic = gotarojo;
        } else if (this.state.sitios[i].estatus === "menor") {
          ic = gotaamarillo;
        } else if (this.state.sitios[i].estatus === "redint") {
          ic = gotaverdepar;
        }

        marker = L.marker(latlng, { icon: ic }).bindPopup(
          customPopup,
          customOptions
        );
        markersLayer.addLayer(marker);
      } else {
        customPopup =
          " <div className='container'><div className='row'><div className='col-12 table-responsive-sm'><table className='table table-striped table-sm table-dark'><thead><tr><th colSpan='4' scope='col' className='text-center'>" +
          this.state.sitios[i].nombre +
          "</th></tr></thead><tbody><tr><th scope='row'>Cota:</th><td>" +
          this.state.sitios[i].cota +
          " msnm</td><th scope='row'>Profundidad:</th><td>" +
          this.state.sitios[i].profundidad +
          " mbs</td></tr><tr><th scope='row'>Temperatura:</th><td>" +
          this.state.sitios[i].temperatura +
          " °C</td><th scope='row'>Presión:</th><td>" +
          this.state.sitios[i].presion +
          " MPa</td></tr><tr><th colSpan='2' scope='row'>Fecha de Lectura</th><td colSpan='2'>" +
          this.state.sitios[i].fechalec +
          "</td></tr><tr><th colSpan='4' ><a className='btn btn-primary btn-sm btn-block' target='_blank' rel='noopener noreferrer' href='/dashboard/" +
          this.state.sitios[i].tipo +
          "/" +
          this.state.sitios[i].idSitio +
          "'>Ir Al Sitio</a></th></tr></tbody></table></div></div></div>";

        if (this.state.sitios[i].estatus === "normal") {
          ic = gotaverde;
        } else if (
          this.state.sitios[i].estatus === "mayor" ||
          this.state.sitios[i].estatus === "cero"
        ) {
          ic = gotarojo;
        } else if (this.state.sitios[i].estatus === "menor") {
          ic = gotaamarillo;
        } else if (this.state.sitios[i].estatus === "redint") {
          ic = gotaverdepar;
        }

        marker = L.marker(latlng, { icon: ic }).bindPopup(
          customPopup,
          customOptions
        );
        markersLayer_2.addLayer(marker);
      }
    }
    var overlay = {
      Caudal: markersLayer,
      Cota: markersLayer_2,
      PLC: markersLayer_3
    };
    var baseMaps = {
      "Mapa Orográfico": orografico,
      "Mapa Satelital": satelite
    };
    L.control.layers(baseMaps, overlay).addTo(this.map);

    this.interval = setInterval(async () => {
      var d = new Date();
      var n = d.getMinutes();
      var seg = d.getSeconds();
      if (localStorage.getItem("pruebas_app") == null) {
        this.props.history.push("/");
      }
      //console.log(seg);
      if (n === 1 || n === 16 || n === 31 || n === 46) {
        if (seg === 0) {
          //pedir al servidor
          const res = await instance.get(
            "https://api.instrutronica-iot.com/api/pruebas/mapa"
          );
          this.setState({ sitios: res.data }, () => {
            this.setState({ contador: this.state.contador + 1 });
          });
          //console.log(this.state.sitios);

          markersLayer.clearLayers();
          markersLayer_2.clearLayers();
          markersLayer_3.clearLayers(); //borrar todas los sitios
          //funcion para armar las gotas y sus respectivos iconos

          for (var i in this.state.sitios) {
            // eslint-disable-next-line
            var fechafinal = "Red Intermitente";
            var latlng = L.latLng({
              lat: this.state.sitios[i].lat,
              lng: this.state.sitios[i].long
            });
            if (this.state.sitios[i].fechalectura !== "red intermitente") {
              fechafinal = this.state.sitios[i].fechalec;
            }

            if (this.state.sitios[i].tipo === 1) {
              customPopup =
                " <div className='container'><div className='row'><div className='col-12 table-responsive-sm'><table className='table table-striped table-sm table-dark'><thead><tr><th colSpan='4' scope='col' className='text-center'>" +
                this.state.sitios[i].nombre +
                "</th></tr></thead><tbody><tr><th scope='row'>Caudal:</th><td>" +
                this.state.sitios[i].caudal +
                " L/S</td><th scope='row'>Volumen:</th><td>" +
                this.state.sitios[i].volumen +
                " M3</td></tr><tr><th colSpan='2' scope='row'>Fecha de Lectura</th><td colSpan='2'>" +
                this.state.sitios[i].fechalec +
                "</td></tr><tr><th colSpan='4' ><a className='btn btn-primary btn-sm btn-block' target='_blank' rel='noopener noreferrer' href='/dashboard/" +
                this.state.sitios[i].tipo +
                "/" +
                this.state.sitios[i].idSitio +
                "'>Ir Al Sitio</a></th></tr></tbody></table></div></div></div>";

              if (this.state.sitios[i].estatus === "normal") {
                ic = gotaverde;
              } else if (
                this.state.sitios[i].estatus === "mayor" ||
                this.state.sitios[i].estatus === "cero"
              ) {
                ic = gotarojo;
              } else if (this.state.sitios[i].estatus === "menor") {
                ic = gotaamarillo;
              } else if (this.state.sitios[i].estatus === "redint") {
                ic = gotaverdepar;
              }

              marker = L.marker(latlng, { icon: ic }).bindPopup(
                customPopup,
                customOptions
              );
              markersLayer.addLayer(marker);
            } else if (this.state.sitios[i].tipo === 7) {
              var customPopup0 =
                " <div className='container'><div className='row'><div className='col-12 table-responsive-sm'><table className='table table-striped table-sm table-dark'><thead><tr><th colSpan='4' scope='col' className='text-center'>" +
                this.state.sitios[i].nombre +
                "</th></tr></thead><tbody><tr><th scope='row'>Caudal:</th><td>" +
                this.state.sitios[i].caudal +
                " L/S</td><th scope='row'>Volumen:</th><td>" +
                this.state.sitios[i].volumen +
                " M3</td>";
              if (this.state.sitios[i].io1 === true) {
                var customPopup1 =
                  "<th scope='row'>Arranque:</th><td> Encendido</td>";
              } else {
                var customPopup1 =
                  "<th scope='row'>Arranque:</th><td> Apagado</td>";
              }
              var customPopup2 =
                "</tr><tr><th colSpan='2' scope='row'>Fecha de Lectura</th><td colSpan='2'>" +
                this.state.sitios[i].fechalec +
                "</td></tr><tr><th colSpan='4' ><a className='btn btn-primary btn-sm btn-block' target='_blank' rel='noopener noreferrer' href='/dashboard/" +
                this.state.sitios[i].tipo +
                "/" +
                this.state.sitios[i].idSitio +
                "'>Ir Al Sitio</a></th></tr></tbody></table></div></div></div>";
              customPopup = customPopup0.concat(customPopup1, customPopup2);
              if (this.state.sitios[i].estatus === "normal") {
                ic = gotaverde;
              } else if (
                this.state.sitios[i].estatus === "mayor" ||
                this.state.sitios[i].estatus === "cero"
              ) {
                ic = gotarojo;
              } else if (this.state.sitios[i].estatus === "menor") {
                ic = gotaamarillo;
              } else if (this.state.sitios[i].estatus === "redint") {
                ic = gotaverdepar;
              }

              marker = L.marker(latlng, { icon: ic }).bindPopup(
                customPopup,
                customOptions
              );
              markersLayer_3.addLayer(marker);
            } else if (this.state.sitios[i].tipo === 2) {
              customPopup =
                " <div className='container'><div className='row'><div className='col-12 table-responsive-sm'><table className='table table-striped table-sm table-dark'><thead><tr><th colSpan='4' scope='col' className='text-center'>" +
                this.state.sitios[i].nombre +
                "</th></tr></thead><tbody><tr><th scope='row'>Caudal:</th><td>" +
                this.state.sitios[i].caudal1 +
                " L/S</td><th scope='row'>Volumen:</th><td>" +
                this.state.sitios[i].volumen1 +
                " M3</td></tr><tr><th scope='row'>Caudal:</th><td>" +
                this.state.sitios[i].caudal2 +
                " L/S</td><th scope='row'>Volumen:</th><td>" +
                this.state.sitios[i].volumen2 +
                " M3</td></tr><tr><th colSpan='2' scope='row'>Fecha de Lectura</th><td colSpan='2'>" +
                this.state.sitios[i].fechalec +
                "</td></tr><tr><th colSpan='4' ><a className='btn btn-primary btn-sm btn-block' target='_blank' rel='noopener noreferrer' href='/dashboard/" +
                this.state.sitios[i].tipo +
                "/" +
                this.state.sitios[i].idSitio +
                "'>Ir Al Sitio</a></th></tr></tbody></table></div></div></div>";

              if (this.state.sitios[i].estatus === "normal") {
                ic = gotaverde;
              } else if (
                this.state.sitios[i].estatus === "mayor" ||
                this.state.sitios[i].estatus === "cero"
              ) {
                ic = gotarojo;
              } else if (this.state.sitios[i].estatus === "menor") {
                ic = gotaamarillo;
              } else if (this.state.sitios[i].estatus === "redint") {
                ic = gotaverdepar;
              }

              marker = L.marker(latlng, { icon: ic }).bindPopup(
                customPopup,
                customOptions
              );
              markersLayer.addLayer(marker);
            } else if (this.state.sitios[i].tipo === 3) {
              customPopup =
                " <div className='container'><div className='row'><div className='col-12 table-responsive-sm'><table className='table table-striped table-sm table-dark'><thead><tr><th colSpan='4' scope='col' className='text-center'>" +
                this.state.sitios[i].nombre +
                "</th></tr></thead><tbody><tr><th scope='row'>Cota:</th><td>" +
                this.state.sitios[i].cota +
                " msnm</td><th scope='row'>Profundidad:</th><td>" +
                this.state.sitios[i].profundidad +
                " mbs</td></tr><tr><th scope='row'>Temperatura:</th><td>" +
                this.state.sitios[i].temperatura +
                " °C</td><th scope='row'>Presión:</th><td>" +
                this.state.sitios[i].presion +
                " cm H2O</td></tr><tr><th colSpan='2' scope='row'>Fecha de Lectura</th><td colSpan='2'>" +
                this.state.sitios[i].fechalec +
                "</td></tr><tr><th colSpan='4' ><a className='btn btn-primary btn-sm btn-block' target='_blank' rel='noopener noreferrer' href='/dashboard/" +
                this.state.sitios[i].tipo +
                "/" +
                this.state.sitios[i].idSitio +
                "'>Ir Al Sitio</a></th></tr></tbody></table></div></div></div>";

              if (this.state.sitios[i].estatus === "normal") {
                ic = gotaverde;
              } else if (
                this.state.sitios[i].estatus === "mayor" ||
                this.state.sitios[i].estatus === "cero"
              ) {
                ic = gotarojo;
              } else if (this.state.sitios[i].estatus === "menor") {
                ic = gotaamarillo;
              } else if (this.state.sitios[i].estatus === "redint") {
                ic = gotaverdepar;
              }

              marker = L.marker(latlng, { icon: ic }).bindPopup(
                customPopup,
                customOptions
              );
              markersLayer_2.addLayer(marker);
            } else if (this.state.sitios[i].tipo === 4) {
              customPopup =
                " <div className='container'><div className='row'><div className='col-12 table-responsive-sm'><table className='table table-striped table-sm table-dark'><thead><tr><th colSpan='4' scope='col' className='text-center'>" +
                this.state.sitios[i].nombre +
                "</th></tr></thead><tbody><tr><th scope='row'>Cota:</th><td>" +
                this.state.sitios[i].cota1 +
                " msnm</td><th scope='row'>Profundidad:</th><td>" +
                this.state.sitios[i].profundidad1 +
                " mbs</td></tr><tr><th scope='row'>Temperatura:</th><td>" +
                this.state.sitios[i].temperatura1 +
                " °C</td><th scope='row'>Presión:</th><td>" +
                this.state.sitios[i].presion1 +
                " cm H2O</td></tr><tr><th scope='row'>Cota:</th><td>" +
                this.state.sitios[i].cota2 +
                " msnm</td><th scope='row'>Profundidad:</th><td>" +
                this.state.sitios[i].profundidad2 +
                " mbs</td></tr><tr><th scope='row'>Temperatura:</th><td>" +
                this.state.sitios[i].temperatura2 +
                " °C</td><th scope='row'>Presión:</th><td>" +
                this.state.sitios[i].presion2 +
                " cm H2O</td></tr><tr><th scope='row'>Cota:</th><td>" +
                this.state.sitios[i].cota3 +
                " msnm</td><th scope='row'>Profundidad:</th><td>" +
                this.state.sitios[i].profundidad3 +
                " mbs</td></tr><tr><th scope='row'>Temperatura:</th><td>" +
                this.state.sitios[i].temperatura3 +
                " °C</td><th scope='row'>Presión:</th><td>" +
                this.state.sitios[i].presion3 +
                " cm H2O</td></tr><tr><th scope='row'>Cota:</th><td>" +
                this.state.sitios[i].cota4 +
                " msnm</td><th scope='row'>Profundidad:</th><td>" +
                this.state.sitios[i].profundidad4 +
                " mbs</td></tr><tr><th scope='row'>Temperatura:</th><td>" +
                this.state.sitios[i].temperatura4 +
                " °C</td><th scope='row'>Presión:</th><td>" +
                this.state.sitios[i].presion4 +
                " cm H2O</td></tr><tr><th colSpan='2' scope='row'>Fecha de Lectura</th><td colSpan='2'>" +
                this.state.sitios[i].fechalec +
                "</td></tr><tr><th colSpan='4' ><a className='btn btn-primary btn-sm btn-block' target='_blank' rel='noopener noreferrer' href='/dashboard/" +
                this.state.sitios[i].tipo +
                "/" +
                this.state.sitios[i].idSitio +
                "'>Ir Al Sitio</a></th></tr></tbody></table></div></div></div>";

              if (this.state.sitios[i].estatus === "normal") {
                ic = gotaverde;
              } else if (
                this.state.sitios[i].estatus === "mayor" ||
                this.state.sitios[i].estatus === "cero"
              ) {
                ic = gotarojo;
              } else if (this.state.sitios[i].estatus === "menor") {
                ic = gotaamarillo;
              } else if (this.state.sitios[i].estatus === "redint") {
                ic = gotaverdepar;
              }

              marker = L.marker(latlng, { icon: ic }).bindPopup(
                customPopup,
                customOptions
              );
              markersLayer_2.addLayer(marker);
            } else if (this.state.sitios[i].tipo === 5) {
              customPopup =
                " <div className='container'><div className='row'><div className='col-12 table-responsive-sm'><table className='table table-striped table-sm table-dark'><thead><tr><th colSpan='4' scope='col' className='text-center'>" +
                this.state.sitios[i].nombre +
                "</th></tr></thead><tbody><tr><th scope='row'>Caudal:</th><td>" +
                this.state.sitios[i].caudal +
                " L/S</td><th scope='row'>Volumen:</th><td>" +
                this.state.sitios[i].volumen +
                " M3</td></tr><tr><tr><th scope='row'>Voltaje 1:</th><td>" +
                this.state.sitios[i].voltaje_1 +
                " V</td><th scope='row'>Corriente 1:</th><td>" +
                this.state.sitios[i].corriente_1 +
                " M3</td></tr><tr><th scope='row'>Potencia 1:</th><td>" +
                this.state.sitios[i].potencia_1 +
                " V</td><th scope='row'>Energia 1:</th><td>" +
                this.state.sitios[i].energia_1 +
                " V</td></tr><tr><th colSpan='2' scope='row'>Fecha de Lectura</th><td colSpan='2'>" +
                this.state.sitios[i].fechalec +
                "</td></tr><tr><th colSpan='4' ><a className='btn btn-primary btn-sm btn-block' target='_blank' rel='noopener noreferrer' href='/dashboard/" +
                this.state.sitios[i].tipo +
                "/" +
                this.state.sitios[i].idSitio +
                "'>Ir Al Sitio</a></th></tr></tbody></table></div></div></div>";

              if (this.state.sitios[i].estatus === "normal") {
                ic = gotaverde;
              } else if (
                this.state.sitios[i].estatus === "mayor" ||
                this.state.sitios[i].estatus === "cero"
              ) {
                ic = gotarojo;
              } else if (this.state.sitios[i].estatus === "menor") {
                ic = gotaamarillo;
              } else if (this.state.sitios[i].estatus === "redint") {
                ic = gotaverdepar;
              }

              marker = L.marker(latlng, { icon: ic }).bindPopup(
                customPopup,
                customOptions
              );
              markersLayer_3.addLayer(marker);
            } else if (this.state.sitios[i].tipo === 6) {
              customPopup =
                " <div className='container'><div className='row'><div className='col-12 table-responsive-sm'><table className='table table-striped table-sm table-dark'><thead><tr><th colSpan='4' scope='col' className='text-center'>" +
                this.state.sitios[i].nombre +
                "</th></tr></thead><tbody><tr><th scope='row'>Caudal:</th><td>" +
                this.state.sitios[i].caudal +
                " L/S</td><th scope='row'>Volumen:</th><td>" +
                this.state.sitios[i].volumen +
                " M3</td></tr><tr><th scope='row'>Temperatura:</th><td>" +
                this.state.sitios[i].temperatura +
                " C°</td><th scope='row'>Elevación:</th><td>" +
                this.state.sitios[i].elevacion +
                " </td></tr><tr><th scope='row'>Conductividad:</th><td>" +
                this.state.sitios[i].conductividad +
                " </td></tr><tr><th colSpan='2' scope='row'>Fecha de Lectura</th><td colSpan='2'>" +
                this.state.sitios[i].fechalec +
                "</td></tr><tr><th colSpan='4' ><a className='btn btn-primary btn-sm btn-block' target='_blank' rel='noopener noreferrer' href='/dashboard/" +
                this.state.sitios[i].tipo +
                "/" +
                this.state.sitios[i].idSitio +
                "'>Ir Al Sitio</a></th></tr></tbody></table></div></div></div>";

              if (this.state.sitios[i].estatus === "normal") {
                ic = gotaverde;
              } else if (
                this.state.sitios[i].estatus === "mayor" ||
                this.state.sitios[i].estatus === "cero"
              ) {
                ic = gotarojo;
              } else if (this.state.sitios[i].estatus === "menor") {
                ic = gotaamarillo;
              } else if (this.state.sitios[i].estatus === "redint") {
                ic = gotaverdepar;
              }

              marker = L.marker(latlng, { icon: ic }).bindPopup(
                customPopup,
                customOptions
              );
              markersLayer.addLayer(marker);
            } else {
              customPopup =
                " <div className='container'><div className='row'><div className='col-12 table-responsive-sm'><table className='table table-striped table-sm table-dark'><thead><tr><th colSpan='4' scope='col' className='text-center'>" +
                this.state.sitios[i].nombre +
                "</th></tr></thead><tbody><tr><th scope='row'>Cota:</th><td>" +
                this.state.sitios[i].cota +
                " msnm</td><th scope='row'>Profundidad:</th><td>" +
                this.state.sitios[i].profundidad +
                " mbs</td></tr><tr><th scope='row'>Temperatura:</th><td>" +
                this.state.sitios[i].temperatura +
                " °C</td><th scope='row'>Presión:</th><td>" +
                this.state.sitios[i].presion +
                " MPa</td></tr><tr><th colSpan='2' scope='row'>Fecha de Lectura</th><td colSpan='2'>" +
                this.state.sitios[i].fechalec +
                "</td></tr><tr><th colSpan='4' ><a className='btn btn-primary btn-sm btn-block' target='_blank' rel='noopener noreferrer' href='/dashboard/" +
                this.state.sitios[i].tipo +
                "/" +
                this.state.sitios[i].idSitio +
                "'>Ir Al Sitio</a></th></tr></tbody></table></div></div></div>";

              if (this.state.sitios[i].estatus === "normal") {
                ic = gotaverde;
              } else if (
                this.state.sitios[i].estatus === "mayor" ||
                this.state.sitios[i].estatus === "cero"
              ) {
                ic = gotarojo;
              } else if (this.state.sitios[i].estatus === "menor") {
                ic = gotaamarillo;
              } else if (this.state.sitios[i].estatus === "redint") {
                ic = gotaverdepar;
              }

              marker = L.marker(latlng, { icon: ic }).bindPopup(
                customPopup,
                customOptions
              );
              markersLayer_2.addLayer(marker);
            }
          }
        }
      }
    }, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    return (
      <div>
        <CuadroAlertas
          sitios={this.state.sitios}
          contador={this.state.contador}
        />
        <div className="container-fluid" style={{ padding: "0" }}>
          <div className="row">
            <Wrapper
              className="col-12"
              width="100vh"
              height="90vh"
              id="map"
            ></Wrapper>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(CargarPuntos2);
