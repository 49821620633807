import React, { Component } from 'react';
import Navigation from '../Navigation';
import Notificaciones from '../overview/Notificaciones';

import axios from 'axios';
import { withRouter } from 'react-router-dom';
import GC from '../overview/GC';
import GV from '../overview/GV';
import G1 from '../overview/G1';
import G2 from '../overview/G2';
import G3 from '../overview/G3';
import G4 from '../overview/G4';
import G5 from '../overview/G5';
import G6 from '../overview/G6';
import G7 from '../overview/G7';
import G8 from '../overview/G8';
import G9 from '../overview/G9';
import G10 from '../overview/G10';
import G11 from '../overview/G11';
import G12 from '../overview/G12';
import G13 from '../overview/G13';
import G14 from '../overview/G14';
import G15 from '../overview/G15';
import G16 from '../overview/G16';
import G17 from '../overview/G17';
import GBatery from '../../dashboard/overview/GBatery';
import GSignal from '../../dashboard/overview/GSignal';
import GTempE from '../../dashboard/overview/GTempE';
export class Graficas extends Component {
  constructor(props) {
    super(props);
    this.state = {
        lecturas: [],
        fecha1:'',
        fecha2:'',
        sitio:''
    };
}



fecha1(event){
    this.setState({fecha1:event.target.value});
    
};
fecha2(event){
    this.setState({fecha2:event.target.value});
};
async cargarlecturas(){        
    const res = await axios.get('https://api.instrutronica-iot.com/api/pruebas/lecturas6/fechas/' + this.state.sitio+'/'+this.state.fecha1+'/'+this.state.fecha2);
    this.setState({ lecturas: res.data});             
};

async componentDidMount() {
    var query = window.location.href;
    var sit = query.split("/");
    const res = await axios.get('https://api.instrutronica-iot.com/api/pruebas/lecturas6/hoy/' + sit[5]);
    this.setState({ lecturas: res.data });
    this.setState({sitio: sit[5]});
    this.interval =  setInterval(async() =>{
        if(localStorage.getItem('pruebas_app')==null){
            this.props.history.push("/");
        }
    },1000);

}

    render() {
        var styles={};
        if(this.state.lecturas.length>0){
            styles = (this.state.lecturas[0].caudal===null&&this.state.lecturas[0].volumen===null) ? {display: 'none'}:{};
        }
        
        
        return (
            <div>
                <Navigation></Navigation>
                <div className="page">
                    <header className="header">
                        <nav className="navbar">
                            <div className="container-fluid">
                                <div className="navbar-holder d-flex align-items-center justify-content-between">
                                    <div className="navbar-header"><button id="toggle-btn" className="menu-btn btn-dark"><i className="icon-bars"> </i></button><span href="index.html" className="navbar-brand">
                                        <div className="brand-text d-none d-md-inline-block"><span style={{ color: "#ccc" }}>{} </span><strong className="text-primary"> {}</strong></div></span></div>
                                    <Notificaciones></Notificaciones>
                                </div>
                            </div>
                        </nav>
                    </header>
                    <div className="breadcrumb-holder">
                        <div className="container-fluid">
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item">Vista General</li>
                                <li className="breadcrumb-item active">Gráficas       </li>
                            </ul>
                        </div>
                    </div>

                    <section className="charts">
        <div className="container-fluid">
        <header>
                                <h1 className="h3 display">Gráficas            </h1>
                                <p>Para ver datos en las gráficas elija primero las fechas y luego presione Cargar Lecturas</p>
                            </header>
          <div className="row">
          <div className="col-lg-12">
                                
          <div className="input-group">
            <input id="fecha1" onChange={this.fecha1.bind(this)}  type="date" className="form-control" placeholder="Fecha Inicial" aria-label="Fecha Inicial" aria-describedby="button-addon4"></input>
            <input id="fecha2" onChange={this.fecha2.bind(this)} type="date" className="form-control" placeholder="Fecha Final" aria-label="Fecha fianl" aria-describedby="button-addon4"></input>
            <div className="input-group-append" id="button-addon4">
                <button className="btn  btn-success" type="button" onClick={this.cargarlecturas.bind(this)}>Cargar Lecturas</button>                
            </div>
        </div> </div>     
            
           
            
          </div>
        </div>
      </section>
      <section className="dashboard-header " style={styles}>              
              <div className="container-fluid">
              <h1 className="display-4">Mace</h1>
                <div className="row d-flex align-items-md-stretch">
                  <GC lecturas={this.state.lecturas} />
                  <GV lecturas={this.state.lecturas} />               
                </div>
              </div>
            </section>

            <section className="dashboard-header ">              
              <div className="container-fluid">
              <h1 className="display-4">Multiparámetros</h1>
                <div className="row d-flex align-items-md-stretch">
                  <G10 lecturas={this.state.lecturas} id="phg"/> 
                  <G11 lecturas={this.state.lecturas} id="phmvg"/> 
                  <G12 lecturas={this.state.lecturas} id="orpg"/> 

                  <G3 lecturas={this.state.lecturas} id="conductividadreg"/>
                  <G4 lecturas={this.state.lecturas} id="conductividadesg" /> 
                  
                  
                  <G6 lecturas={this.state.lecturas} id="salinidadg"/>
                  <G7 lecturas={this.state.lecturas} id="solidosdisg"/>
                  <G5 lecturas={this.state.lecturas} id="resistividadg"/>

                  <G8 lecturas={this.state.lecturas} id="densidadg"/> 
                  <G15 lecturas={this.state.lecturas} id="turbg"/>
                  <G17 lecturas={this.state.lecturas} id="solidosg"/> 

                  <G9 lecturas={this.state.lecturas} id="presionbarg"/>   
                  
                  
                  <G13 lecturas={this.state.lecturas} id="oxigenodisg"/> 
                  <G14 lecturas={this.state.lecturas} id="satoxigenog"/> 
                   
                  <G16 lecturas={this.state.lecturas} id="presionparg"/> 
                  
                  <G1 lecturas={this.state.lecturas} id="temperaturag"/>
                  <G2 lecturas={this.state.lecturas} id="elevaciong"/>              
                </div>
              </div>
            </section>

            <section className="dashboard-header " >              
              <div className="container-fluid">
                <div className="row d-flex align-items-md-stretch">
                  <GBatery lecturas={this.state.lecturas} />
                  <GSignal lecturas={this.state.lecturas} />  
                  <GTempE lecturas={this.state.lecturas} />               
                </div>
              </div>
            </section>
       


                  
           

                    <footer className="main-footer">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-sm-6">
                                    <p>Instrutrónica -Liderti &copy; 2019</p>
                                </div>
                                <div className="col-sm-6 text-right">
                                    <p>Version 1.0.0</p>
                                </div>
                            </div>
                        </div>
                    </footer>
                </div>
            </div>
        )
    }
}

export default withRouter(Graficas)
