import React, { Component } from 'react';
import Navigation from '../Navigation';
import GBatery from '../../dashboard/overview/GBatery';
import GSignal from '../../dashboard/overview/GSignal';
import GTempE from '../../dashboard/overview/GTempE';
import Notificaciones from '../overview/Notificaciones';
import GCota from '../overview/GCota';
import GPresion from '../overview/GPresion';
import GProfundidad from '../overview/GProfundidad';
import GTemp from '../overview/GTemp';
import GCota2 from '../overview/GCota2';
import GPresion2 from '../overview/GPresion2';
import GProfundidad2 from '../overview/GProfundidad2';
import GTemp2 from '../overview/GTemp2';
import GCota3 from '../overview/GCota3';
import GPresion3 from '../overview/GPresion3';
import GProfundidad3 from '../overview/GProfundidad3';
import GTemp3 from '../overview/GTemp3';
import GCota4 from '../overview/GCota4';
import GPresion4 from '../overview/GPresion4';
import GProfundidad4 from '../overview/GProfundidad4';
import GTemp4 from '../overview/GTemp4';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
export class Graficas extends Component {
  constructor(props) {
    super(props);
    this.state = {
        lecturas: [],
        fecha1:'',
        fecha2:'',
        sitio:''
    };
}



fecha1(event){
    this.setState({fecha1:event.target.value});
    
};
fecha2(event){
    this.setState({fecha2:event.target.value});
};
async cargarlecturas(){        
    const res = await axios.get('https://api.instrutronica-iot.com/api/pruebas/lecturas4/fechas/' + this.state.sitio+'/'+this.state.fecha1+'/'+this.state.fecha2);
    this.setState({ lecturas: res.data});             
};

async componentDidMount() {
    var query = window.location.href;
    var sit = query.split("/");
    const res = await axios.get('https://api.instrutronica-iot.com/api/pruebas/lecturas4/hoy/' + sit[5]);
    this.setState({ lecturas: res.data });
    this.setState({sitio: sit[5]});
    this.interval =  setInterval(async() =>{
        if(localStorage.getItem('pruebas_app')==null){
            this.props.history.push("/");
        }
    },1000);

}

    render() {
        var styles1={};
        var styles2={};
        var styles3={};
        var styles4={};
        if(this.state.lecturas.length>0){
            styles1 = (this.state.lecturas[0].cota_1===null&&this.state.lecturas[0].profundidad_1===null) ? {display: 'none'}:{};
            styles2 = (this.state.lecturas[0].cota_2===null&&this.state.lecturas[0].profundidad_2===null) ? {display: 'none'}:{};
            styles3 = (this.state.lecturas[0].cota_3===null&&this.state.lecturas[0].profundidad_3===null) ? {display: 'none'}:{};
            styles4 = (this.state.lecturas[0].cota_4===null&&this.state.lecturas[0].profundidad_4===null) ? {display: 'none'}:{};
        }
        return (
            <div>
                <Navigation></Navigation>
                <div className="page">
                    <header className="header">
                        <nav className="navbar">
                            <div className="container-fluid">
                                <div className="navbar-holder d-flex align-items-center justify-content-between">
                                    <div className="navbar-header"><button id="toggle-btn" className="menu-btn btn-dark"><i className="icon-bars"> </i></button><span href="index.html" className="navbar-brand">
                                        <div className="brand-text d-none d-md-inline-block"><span style={{ color: "#ccc" }}>{} </span><strong className="text-primary"> {}</strong></div></span></div>
                                    <Notificaciones></Notificaciones>
                                </div>
                            </div>
                        </nav>
                    </header>
                    <div className="breadcrumb-holder">
                        <div className="container-fluid">
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item">Vista General</li>
                                <li className="breadcrumb-item active">Gráficas       </li>
                            </ul>
                        </div>
                    </div>

                    <section className="charts">
        <div className="container-fluid">
        <header>
                                <h1 className="h3 display">Gráficas            </h1>
                                <p>Para ver datos en las gráficas elija primero las fechas y luego presione Cargar Lecturas</p>
                            </header>
          <div className="row">
          <div className="col-lg-12">
                                
          <div className="input-group">
            <input id="fecha1" onChange={this.fecha1.bind(this)}  type="date" className="form-control" placeholder="Fecha Inicial" aria-label="Fecha Inicial" aria-describedby="button-addon4"></input>
            <input id="fecha2" onChange={this.fecha2.bind(this)} type="date" className="form-control" placeholder="Fecha Final" aria-label="Fecha fianl" aria-describedby="button-addon4"></input>
            <div className="input-group-append" id="button-addon4">
                <button className="btn  btn-success" type="button" onClick={this.cargarlecturas.bind(this)}>Cargar Lecturas</button>                
            </div>
        </div> </div>     
            
           
            
          </div>
        </div>
      </section>
      <section className="dashboard-header " style={styles1}>
              <div className="container-fluid">
                <h1 className="display-4">Piezometro 1</h1>
                <div className="row d-flex ">
                 <GCota lecturas={this.state.lecturas} />
                  <GProfundidad lecturas={this.state.lecturas} />
                  <GTemp lecturas={this.state.lecturas} />
                  <GPresion lecturas={this.state.lecturas} />
                  </div>
              </div>
            </section>

                   <section className="dashboard-header " style={styles2}>
              <div className="container-fluid">
                <h1 className="display-4">Piezometro 2</h1>
                <div className="row d-flex align-items-md-stretch">
                <GCota2 lecturas={this.state.lecturas} />
                  <GProfundidad2 lecturas={this.state.lecturas} />
                  <GTemp2 lecturas={this.state.lecturas} />
                  <GPresion2 lecturas={this.state.lecturas} /> 
                  </div>
              </div>
            </section> 
       

            <section className="dashboard-header " style={styles3}>
              <div className="container-fluid">
                <h1 className="display-4">Piezometro 3</h1>
                <div className="row d-flex align-items-md-stretch">
                <GCota3 lecturas={this.state.lecturas} />
                  <GProfundidad3 lecturas={this.state.lecturas} />
                  <GTemp3 lecturas={this.state.lecturas} />
                  <GPresion3 lecturas={this.state.lecturas} />  
                  </div>
              </div>
            </section> 

                  
            <section className="dashboard-header " style={styles4}>
              <div className="container-fluid">
                <h1 className="display-4">Piezometro 4</h1>
                <div className="row d-flex align-items-md-stretch">
                    <GCota4 lecturas={this.state.lecturas} />
                  <GProfundidad4 lecturas={this.state.lecturas} />
                  <GTemp4 lecturas={this.state.lecturas} />
                  <GPresion4 lecturas={this.state.lecturas} />  
                  </div>
              </div>
            </section> 

            <section className="dashboard-header ">
              <div className="container-fluid">               
                <div className="row d-flex align-items-md-stretch">
                <GBatery lecturas={this.state.lecturas}  />
                  <GTempE lecturas={this.state.lecturas}  />
                  <GSignal lecturas={this.state.lecturas}  />                
                </div>
              </div>
            </section>

                    <footer className="main-footer">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-sm-6">
                                    <p>Instrutrónica -Liderti &copy; 2019</p>
                                </div>
                                <div className="col-sm-6 text-right">
                                    <p>Version 1.0.0</p>
                                </div>
                            </div>
                        </div>
                    </footer>
                </div>
            </div>
        )
    }
}

export default withRouter(Graficas)
