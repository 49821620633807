import React, { Component } from 'react'
import Contadores from './Contadores';
import Notificaciones from './Notificaciones';
import GBatery from '../../dashboard/overview/GBatery';
import GSignal from '../../dashboard/overview/GSignal';
import GTempE from '../../dashboard/overview/GTempE';
import GC from './GC';
import GV from './GV';
import G1 from './G1';
import G2 from './G2';
import G3 from './G3';
import G4 from './G4';
import G5 from './G5';
import G6 from './G6';
import G7 from './G7';
import G8 from './G8';
import G9 from './G9';
import G10 from './G10';
import G11 from './G11';
import G12 from './G12';
import G13 from './G13';
import G14 from './G14';
import G15 from './G15';
import G16 from './G16';
import G17 from './G17';

export class Page extends Component {
  
  render() {

    function renderi(num) {
      
     
      if (num.length > 0) {

        const styles = (num[2].ultimalectura.caudal===null&&num[2].ultimalectura.volumen===null) ? {display: 'none'}:{};
       
        return (
          <div className="page">

            <header className="header">
              <nav className="navbar">
                <div className="container-fluid">
                  <div className="navbar-holder d-flex align-items-center justify-content-between">
                    <div className="navbar-header"><button id="toggle-btn" className="menu-btn btn-dark"><i className="icon-bars"> </i></button><span href="index.html" className="navbar-brand">
                      <div className="brand-text d-none d-md-inline-block"><span style={{ color: "#ccc" }}>{num[0].nombresitio[0].nombre} </span><strong className="text-primary" style={styles}> {num[6].estatus}</strong></div></span></div>
                    <Notificaciones />
                  </div>
                </div>
              </nav>
            </header>

            <Contadores lecturas={num[2].ultimalectura} />
            
            <section className="dashboard-header " style={styles}>              
              <div className="container-fluid">
              <h1 className="display-4">Mace</h1>
                <div className="row d-flex align-items-md-stretch">
                  <GC lecturas={num[1].lecturas} />
                  <GV lecturas={num[1].lecturas} />               
                </div>
              </div>
            </section>

            

            <section className="dashboard-header ">              
              <div className="container-fluid">
              <h1 className="display-4">Multiparámetros</h1>
                <div className="row d-flex align-items-md-stretch">
                  <G10 lecturas={num[1].lecturas} id="phg"/> 
                  <G11 lecturas={num[1].lecturas} id="phmvg"/> 
                  <G12 lecturas={num[1].lecturas} id="orpg"/> 

                  <G3 lecturas={num[1].lecturas} id="conductividadreg"/>
                  <G4 lecturas={num[1].lecturas} id="conductividadesg" /> 
                  
                  
                  <G6 lecturas={num[1].lecturas} id="salinidadg"/>
                  <G7 lecturas={num[1].lecturas} id="solidosdisg"/>
                  <G5 lecturas={num[1].lecturas} id="resistividadg"/>

                  <G8 lecturas={num[1].lecturas} id="densidadg"/> 
                  <G15 lecturas={num[1].lecturas} id="turbg"/>
                  <G17 lecturas={num[1].lecturas} id="solidosg"/> 

                  <G9 lecturas={num[1].lecturas} id="presionbarg"/>   
                  
                  
                  <G13 lecturas={num[1].lecturas} id="oxigenodisg"/> 
                  <G14 lecturas={num[1].lecturas} id="satoxigenog"/> 
                   
                  <G16 lecturas={num[1].lecturas} id="presionparg"/> 
                  
                  <G1 lecturas={num[1].lecturas} id="temperaturag"/>
                  <G2 lecturas={num[1].lecturas} id="elevaciong"/>              
                </div>
              </div>
            </section>

            <section className="dashboard-header " >              
              <div className="container-fluid">
                <div className="row d-flex align-items-md-stretch">
                  <GBatery lecturas={num[1].lecturas} />
                  <GTempE lecturas={num[1].lecturas} />   
                  <GSignal lecturas={num[1].lecturas} />               
                </div>
              </div>
            </section>

           

            <footer className="main-footer">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-sm-6">
                    <p>Instrutrónica -Liderti &copy; 2019</p>
                  </div>
                  <div className="col-sm-6 text-right">
                    <p>Version 5.0.1</p>
                  </div>
                </div>
              </div>
            </footer>
          </div>
        )
      } else {
        return (
          <div>Cargando</div>
        )
      }
    }

    return renderi(this.props.lecturas);
  }
}

export default Page

