import React, { Component } from 'react'

export class Contadores extends Component {

   
    render() {
      

      function contadores(s) {
        /*function convertirfecha(fecha){
          if(fecha!==''){
            //console.log(this.state.sitios[i].fechalectura);
            var fec = new Date(""+fecha+"");
            var hora = fec.toLocaleTimeString({timeZone: 'UTC-5' });
            var dia= fec.toLocaleDateString({timeZone: 'UTC-5' });
            return dia+' '+hora;
          }else{
            return '-';
          }
        };*/
        
        return (
          <section className="dashboard-counts section-padding">
          <div className="container-fluid">
            <div className="row">
              
              <div className="col-xl-4 col-md-4 col-6">
                <div className="wrapper count-title d-flex">
                  <div className="icon"><i className="icon-padnote"></i></div>
                  <div className="name"><strong className="text-uppercase">Caudal L/S</strong><span>Última Lectura</span>
                    <div className="count-number">{(s.caudal===null)?'null':(s.caudal)}</div>
                  </div>
                </div>
              </div>
             
              <div className="col-xl-4 col-md-4 col-6">
                <div className="wrapper count-title d-flex">
                  <div className="icon"><i className="icon-padnote"></i></div>
                  <div className="name"><strong className="text-uppercase">Volumen M3</strong><span>Última Lectura</span>
                    <div className="count-number">{(s.volumen===null)?'null':(s.volumen)}</div>
                  </div>
                </div>
              </div>
              
              <div className="col-xl-4 col-md-4 col-6">
                <div className="wrapper count-title d-flex">
                  <div className="icon"><i className="icon-padnote"></i></div>
                  <div className="name"><strong className="text-uppercase">Fecha y Hora</strong><span>Última Lectura</span>
                    <div className="count-number">{s.fechalec}</div>
                  </div>
                </div>
              </div>

              <div className="col-xl-4 col-md-4 col-6">
                <div className="wrapper count-title d-flex">
                  <div className="icon"><i className="icon-padnote"></i></div>
                  <div className="name"><strong className="text-uppercase">Temperatura Eagle C°</strong><span>Última Lectura</span>
                    <div className="count-number">{(s.temp_equipo===null)?'null':(s.temp_equipo)}</div>
                  </div>
                </div>
              </div>

              <div className="col-xl-4 col-md-4 col-6">
                <div className="wrapper count-title d-flex">
                  <div className="icon"><i className="icon-padnote"></i></div>
                  <div className="name"><strong className="text-uppercase">Batería V</strong><span>Última Lectura</span>
                    <div className="count-number">{(s.bateria===null)?'null':(s.bateria)}</div>
                  </div>
                </div>
              </div>

              <div className="col-xl-4 col-md-4 col-6">
                <div className="wrapper count-title d-flex">
                  <div className="icon"><i className="icon-padnote"></i></div>
                  <div className="name"><strong className="text-uppercase">Nivel de Señal dBm</strong><span>Última Lectura</span>
                    <div className="count-number">{(s.senal===null)?'null':(-1*s.senal)}</div>
                  </div>
                </div>
              </div>
            
            </div>
          </div>
        </section>
      )
        
      }

      return contadores(this.props.lecturas);
        
    }
}

export default Contadores
