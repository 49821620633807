import React, { Component } from 'react';
import logo from '../mapa/img/minaperulogo.png';

export default class Navigation extends Component {
 
  
    render() {      
        return (
            
            <nav className="side-navbar">
              <div className="side-navbar-wrapper">
                
                <div className="sidenav-header d-flex align-items-center justify-content-center">
                  
                  <div className="sidenav-header-inner text-center"><img src={logo} alt="logo" className="img-fluid rounded-circle"/>
                    <h2 className="h5">Antapaccay</h2><span>Admin Dashboard</span>
                  </div>
                  
                  <div className="sidenav-header-logo"><span className="brand-small text-center"> <strong>A</strong><strong className="text-primary">T</strong></span></div>
                </div>
                
               
                <div className="admin-menu">
                  <h5 className="sidenav-heading">Administración del Sitio</h5>
                  <ul id="side-admin-menu" className="side-menu list-unstyled"> 
                  <li> <a href={ `/admin/modnormac` }> <i className="icon-screen"> </i>Modificar Configuración CONAGUA</a></li>
                    <li> <a href={ `/admin/modsitios` }> <i className="icon-screen"> </i>Modificar Sitios</a></li>
                    <li> <a href={ `/admin/modequipos` }> <i className="icon-flask"> </i>Modificar Configuración Equipos
                        <div className="badge badge-info">Precaución</div></a></li>
                  </ul>
                </div>
              </div>
            </nav>
        )
    }
}
