import React, { Component } from 'react';

import { withRouter } from 'react-router-dom';
import auth from '../../../auth';

export class Notificaciones extends Component {
    render() {
        return (
            <ul className="nav-menu list-unstyled d-flex flex-md-row align-items-md-center">
               
           
            
            
            <li className="nav-item"><a href="/mapa" className="nav-link logout"> <span className="d-none d-sm-inline-block">Ir Al Mapa</span><i className="fa fa-map-marker"></i></a></li>
            <li className="nav-item"><span onClick={()=>{
                  auth.logout(()=>{
                      this.props.history.push("/");
                  });
              }} className="nav-link logout"> <span style={{color: "white", cursor:"pointer", fontsize:"0.8rem"}} className="d-none d-sm-inline-block">Salir Del Sistema</span><i style={{color: "white", cursor:"pointer"}} className="fa fa-sign-out"></i></span></li>
          </ul>
        )
    }
}

export default withRouter(Notificaciones)
