import React, { Component } from 'react'
import iconVerde from './img/gotaverde.png';
import iconRojo from './img/gotaroja.gif';
import iconAmarillo from './img/gotaamarilla.gif';

export class Alerta extends Component {
    
    
    render() {
        var icono = iconVerde;
        var mensaje ="Estatus Normal";
        var estilo="col-12";
      if(this.props.alerta.estatus!=='redint'){
            estilo="col-xl-2 col-lg-2 col-md-4 col-sm-12 col-12";            
            if(this.props.alerta.estatus==='cero'){
               icono= iconRojo;
               mensaje = 'Sitio '+this.props.alerta.nombre+' tiene lectura cero.';
            }else if(this.props.alerta.estatus==='menor'){
                icono= iconAmarillo;
                mensaje = 'Sitio '+this.props.alerta.nombre+' tiene lectura menor al promedio.';
            }
            else if(this.props.alerta.estatus==='mayor'){
                icono= iconRojo;
                mensaje = 'Sitio '+this.props.alerta.nombre+' tiene lectura mayor al promedio.';
            }
            return (
            
                <div  key={this.props.alerta.idSitio} className={estilo}>
                    <div className="container-fluid" style={{margin:"0"}}>
                        <div className="row" style={{ borderColor:"white"}}>
                            
                                <div  className="col-2 text-center"><img src={icono} style={{height:"15px"}} alt="semáforo"/></div>
                                <div className="col-10 text-center text-primary" style={{color:'#000', fontSize:"12px"}}>{mensaje}</div>
                            
                            
                        </div>
                    </div>
                </div>
            )
        }else if(this.props.alerta.estatus!=='normal'){
            return null;
        }
         else{
            return null;
        }

       
        
        
    }
}

export default Alerta
