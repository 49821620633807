import React, { Component } from "react";
import Navigation from "../Navigation";
import Notificaciones from "../overview/Notificaciones";
import axios from "axios";
import { withRouter } from "react-router-dom";
import https from "https";
const instance = axios.create({
  httpsAgent: new https.Agent({
    rejectUnauthorized: false
  })
});
export class Informacion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lecturas: [
        {
          sitio: [
            {
              _id: "-",
              idEquipo: "-",
              idMedidor: "-",
              nombre: "-",
              x: 0,
              y: 0,
              z: 0,
              cantidad_equipos: "-",
              UND: "UND",
              min: 0,
              max: 0
            }
          ]
        },
        {
          equipo: [
            {
              _id: "-",
              tipo: "-",
              modelo: "-",
              firmware: "-",
              marca: "-",
              numerotel: "-",
              imei: 0
            }
          ]
        },
        {
          equipom: [
            {
              _id: "-",
              tipo: "-",
              marca: "-",
              modelo: "-",
              protocolo: "-"
            }
          ]
        }
      ],
      sitioid: ""
    };
  }

  async componentDidMount() {
    var query = window.location.href;
    var sit = query.split("/");
    const res = await instance.get(
      "https://api.instrutronica-iot.com/api/pruebas/datos/" + sit[5]
    );
    this.setState({ lecturas: res.data }, () => {
      console.log(this.state.lecturas[0].sitio[0].nombre);
    });
    this.setState({ sitioid: sit[5] });
    this.interval = setInterval(async () => {
      if (localStorage.getItem("pruebas_app") == null) {
        this.props.history.push("/");
      }
    }, 1000);
  }

  render() {
    return (
      <div>
        <Navigation></Navigation>
        <div className="page">
          <header className="header">
            <nav className="navbar">
              <div className="container-fluid">
                <div className="navbar-holder d-flex align-items-center justify-content-between">
                  <div className="navbar-header">
                    <button id="toggle-btn" className="menu-btn btn-dark">
                      <i className="icon-bars"> </i>
                    </button>
                    <span href="index.html" className="navbar-brand">
                      <div className="brand-text d-none d-md-inline-block">
                        <span style={{ color: "#ccc" }}>{} </span>
                        <strong className="text-primary"> {}</strong>
                      </div>
                    </span>
                  </div>
                  <Notificaciones></Notificaciones>
                </div>
              </div>
            </nav>
          </header>
          <div className="breadcrumb-holder">
            <div className="container-fluid">
              <ul className="breadcrumb">
                <li className="breadcrumb-item">Vista General</li>
                <li className="breadcrumb-item active">Información </li>
              </ul>
            </div>
          </div>

          <section className="dashboard-header section-padding">
            <div className="container-fluid">
              <div className="row d-flex align-items-md-stretch">
                <div className="col-lg-4 col-md-12 flex-lg-last flex-md-first align-self-baseline">
                  <div className="card  mb-3 ">
                    <div className="card-header ">Datos del Sitio</div>
                    <div className="card-body">
                      <h5 className="card-title">Nombre del Sitio</h5>
                      <p className="card-text header">
                        {this.state.lecturas[0].sitio[0].nombre}
                      </p>

                      <h5 className="card-title">Ubicación</h5>
                      <ul className="list-group list-group-horizontal flex-fill">
                        <li className="list-group-item ">
                          X: {this.state.lecturas[0].sitio[0].x}
                        </li>
                        <li className="list-group-item ">
                          Y: {this.state.lecturas[0].sitio[0].y}
                        </li>
                        <li className="list-group-item ">
                          Z: {this.state.lecturas[0].sitio[0].z}
                        </li>
                      </ul>
                      <br />
                      <h5 className="card-title">
                        Rango del límite del Caudal
                      </h5>
                      <ul className="list-group list-group-horizontal flex-fill">
                        <li className="list-group-item ">
                          Min: {this.state.lecturas[0].sitio[0].min}
                        </li>
                        <li className="list-group-item ">
                          Max: {this.state.lecturas[0].sitio[0].max}
                        </li>
                      </ul>
                      <br />
                      <h5 className="card-title">Cantidad de Equipos</h5>
                      <p className="card-text">
                        {this.state.lecturas[0].sitio[0].cantidad_equipos}
                      </p>
                      <h5 className="card-title">UND</h5>
                      <p className="card-text">
                        {this.state.lecturas[0].sitio[0].UND}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-md-12 flex-lg-last flex-md-first align-self-baseline">
                  <div className="card  mb-3 ">
                    <div className="card-header ">Datos del Equipo Medidor</div>
                    <div className="card-body">
                      <h5 className="card-title">Tipo de Equipo</h5>
                      <p className="card-text">
                        {this.state.lecturas[2].equipom[0].tipo}
                      </p>
                      <h5 className="card-title">Marca del Equipo</h5>
                      <p className="card-text">
                        {this.state.lecturas[2].equipom[0].marca}
                      </p>
                      <h5 className="card-title">Modelo del Equipo</h5>
                      <p className="card-text">
                        {this.state.lecturas[2].equipom[0].modelo}
                      </p>
                      <h5 className="card-title">
                        Protocolo de comunicación del Equipo
                      </h5>
                      <p className="card-text">
                        {this.state.lecturas[2].equipom[0].protocolo}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-md-12 flex-lg-last flex-md-first align-self-baseline">
                  <div className="card  mb-3 ">
                    <div className="card-header ">
                      Datos del Equipo Transmisor
                    </div>
                    <div className="card-body">
                      <h5 className="card-title">Modelo del Equipo</h5>
                      <p className="card-text">
                        {this.state.lecturas[1].equipo[0].modelo}
                      </p>
                      <h5 className="card-title">Marca del Equipo</h5>
                      <p className="card-text">
                        {this.state.lecturas[1].equipo[0].marca}
                      </p>
                      <h5 className="card-title">Firmware del Equipo</h5>
                      <p className="card-text">
                        {this.state.lecturas[1].equipo[0].firmware}
                      </p>
                      <h5 className="card-title">
                        Número de teléfono del Equipo
                      </h5>
                      <p className="card-text">
                        {this.state.lecturas[1].equipo[0].numerotel}
                      </p>
                      <h5 className="card-title">IMEI del Equipo</h5>
                      <p className="card-text">
                        {this.state.lecturas[1].equipo[0].imei}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <footer className="main-footer">
            <div className="container-fluid">
              <div className="row">
                <div className="col-sm-6">
                  <p>Instrutrónica -Liderti &copy; 2019</p>
                </div>
                <div className="col-sm-6 text-right">
                  <p>Version 1.0.0</p>
                </div>
              </div>
            </div>
          </footer>
        </div>
      </div>
    );
  }
}

export default withRouter(Informacion);
