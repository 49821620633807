import React, { Component } from 'react';
import logo from '../mapa/img/minaperulogo.png';

export default class Navigation extends Component {
 
  
  render() {
    var query = window.location.href;
    var sit=query.split("/");
    //console.log(sit[5]);
    const tip =  JSON.parse(localStorage.getItem('tipo'));
          if(tip.tipo !=='admin'){
            return (
          
              <nav className="side-navbar">
                <div className="side-navbar-wrapper">
                  
                  <div className="sidenav-header d-flex align-items-center justify-content-center">
                    
                    <div className="sidenav-header-inner text-center"><img src={logo} alt="logo" className="img-fluid rounded-circle"/>
                      <h2 className="h5">Antapaccay</h2><span>Dashboard</span>
                    </div>
                    
                    <div className="sidenav-header-logo"><span className="brand-small text-center"> <strong>B</strong><strong className="text-primary">D</strong></span></div>
                  </div>
                  
                  <div className="main-menu">
                    <h5 className="sidenav-heading">Principal</h5>
                    <ul id="side-main-menu" className="side-menu list-unstyled">                  
                      <li><a href={ `/dashboard/6/${sit[5]}` }> <i className="icon-home"></i>Vista General                             </a></li>
                      
                      <li><a href={ `/graficas/6/${sit[5]}` }> <i className="fa fa-bar-chart"></i>Gráficas </a>
                        
                      </li>
                      <li><a href={ `/lecturas/6/${sit[5]}` }> <i className="icon-grid"></i>Lecturas y Reportes </a>
                        
                      </li>
                     
                      <li><a href={ `/informacion/6/${sit[5]}` } aria-expanded="false" > <i className="icon-interface-windows"></i>Información del Sitio </a>
                       
                      </li>
                    </ul>
                  </div>
                  
                </div>
              </nav>
          )
          }else{
            return (
          
              <nav className="side-navbar">
                <div className="side-navbar-wrapper">
                  
                  <div className="sidenav-header d-flex align-items-center justify-content-center">
                    
                    <div className="sidenav-header-inner text-center"><img src={logo} alt="logo" className="img-fluid rounded-circle"/>
                      <h2 className="h5">Antapaccay</h2><span>Dashboard</span>
                    </div>
                    
                    <div className="sidenav-header-logo"><span className="brand-small text-center"> <strong>B</strong><strong className="text-primary">D</strong></span></div>
                  </div>
                  
                  <div className="main-menu">
                    <h5 className="sidenav-heading">Principal</h5>
                    <ul id="side-main-menu" className="side-menu list-unstyled">                  
                      <li><a href={ `/dashboard/6/${sit[5]}` }> <i className="icon-home"></i>Vista General                             </a></li>
                      
                      <li><a href={ `/graficas/6/${sit[5]}` }> <i className="fa fa-bar-chart"></i>Gráficas </a>
                        
                      </li>
                      <li><a href={ `/lecturas/6/${sit[5]}` }> <i className="icon-grid"></i>Lecturas y Reportes </a>
                        
                      </li>
                     
                      <li><a href={ `/informacion/6/${sit[5]}` } aria-expanded="false" > <i className="icon-interface-windows"></i>Información del Sitio </a>
                       
                      </li>
                    </ul>
                  </div>
                  <div className="admin-menu">
                    <h5 className="sidenav-heading">Administración del Sitio</h5>
                    <ul id="side-admin-menu" className="side-menu list-unstyled"> 
                      <li> <a href={ `/admin/modsitios` }> <i className="icon-screen"> </i>Modificar Sitios</a></li>
                      <li> <a href={ `/admin/modequipos` }> <i className="icon-flask"> </i>Modificar Configuración Equipos
                          <div className="badge badge-info">Precaución</div></a></li>
                    </ul>
                  </div>
                </div>
              </nav>
          )
          }
      
  }
}
