import React, { Component } from 'react'
import axios from 'axios';
import {ToastsContainer, ToastsStore} from 'react-toasts';
export class SetControl extends Component {
    constructor(props) {
        super(props);
        this.state = {
            lecturas: 
                
                   
                        {
                            io1: true,                            
                            idSitio:"PLC1",
	                         idEquipo:"P1"
                            
                            
                        },
                    
                
            
            sitioid:''
        };
    }

    recibirDatosP = (childData) =>{
        this.setState({lecturas: childData});
    }
    onInputChange = (e) => {       
        const todoestado=this.state.lecturas;
        if(e.target.name==='io1'){
            todoestado.io1=e.target.value;
            this.setState({lecturas:todoestado})
        }
    }

    actualizarDatos = async (e) =>{
        console.log('mandar datos');
        const updatedNote = {
            idSitio:this.state.lecturas.idSitio,
	        idEquipo:this.state.lecturas.idEquipo,
            io1: this.state.lecturas.io1
            
        };
        const res = await axios.post('https://api.instrutronica-iot.com/api/pruebas/rxplc/control', updatedNote);
       console.log(res);
        if(res.data.status==='ok'){
            ToastsStore.success("Arranque Actualizado");
        }else{
            ToastsStore.error("Fallo Actualización");
        }
    }
    async componentDidMount() {
        this.interval =  setInterval(async() =>{
            const tip =  JSON.parse(localStorage.getItem('tipo'));
            if(tip.tipo !=='admin'){
                this.props.history.push("/");
            }
        },1000);
    }
   
    render() {
        return (
            <div className="col-lg-6">              
              <form className="form-inline">
                <div className="form-row align-items-center">
                    <div className="col-auto my-1">
                    <label className="mr-sm-2" htmlFor="inlineFormCustomSelect">Set Arranque</label>
                    <select name="io1" className="custom-select mr-sm-2" id="inlineFormCustomSelect" onChange={this.onInputChange} value={this.state.lecturas.io1}>
                        <option defaultValue>Elige...</option>
                        <option value="true">Encendido</option>
                        <option value="false">Apagado</option>
                    </select>
                    </div>                    
                    <div className="col-auto my-1">
                    <button type="button" className="btn btn-primary" onClick={this.actualizarDatos} >Mandar Instrucción</button>
                    </div>
                    <ToastsContainer store={ToastsStore}  />
                </div>
                </form>
            </div>
        )
    }
}

export default SetControl
