import React, { Component } from 'react'

export class Contadores extends Component {

   
    render() {
      

      function contadores(s) {
        console.log(isNaN(s.cota_1));
        const styles1 = (s.cota_1===null&&s.profundidad_1===null ) ? {display: 'none'}:{}; 
        const styles2 = (s.cota_2===null&&s.profundidad_2===null ) ? {display: 'none'}:{}; 
        const styles3 = (s.cota_3===null&&s.profundidad_3===null ) ? {display: 'none'}:{}; 
        const styles4 = (s.cota_4===null&&s.profundidad_4===null ) ? {display: 'none'}:{}; 
        return (
          <section className="dashboard-counts section-padding">
          <div className="container-fluid">
            <div className="row">
            <div className="col-xl-2 col-md-2 col-6" style={styles1}>
                <div className="wrapper count-title d-flex">
                  <div className="icon"><i className="icon-padnote"></i></div>
                  <div className="name"><strong className="text-uppercase">Digitos 1</strong><span>Última Lectura</span>
                    <div className="count-number">{(s.digitos_1===null)?'null':(s.digitos_1)}</div>
                  </div>
                </div>
              </div>

              <div className="col-xl-3 col-md-3 col-6" style={styles1}>
                <div className="wrapper count-title d-flex">
                  <div className="icon"><i className="icon-padnote"></i></div>
                  <div className="name"><strong className="text-uppercase">Cota 1 msnm</strong><span>Última Lectura</span>
                    <div className="count-number">{(s.cota_1===null||isNaN(s.cota_1))?'null':(s.cota_1.toFixed(3))}</div>
                  </div>
                </div>
              </div>
             
              <div className="col-xl-3 col-md-3 col-6" style={styles1}>
                <div className="wrapper count-title d-flex">
                  <div className="icon"><i className="icon-padnote"></i></div>
                  <div className="name"><strong className="text-uppercase">Profundidad 1 mbs</strong><span>Última Lectura</span>
                    <div className="count-number">{(s.profundidad_1===null||isNaN(s.profundidad_1))?'null':(s.profundidad_1.toFixed(3))}</div>
                  </div>
                </div>
              </div>
              <div className="col-xl-2 col-md-2 col-6" style={styles1}>
                <div className="wrapper count-title d-flex">
                  <div className="icon"><i className="icon-padnote"></i></div>
                  <div className="name"><strong className="text-uppercase">Temperatura 1 ºC</strong><span>Última Lectura</span>
                    <div className="count-number">{(s.temperatura_1===null||isNaN(s.temperatura_1))?'null':(s.temperatura_1)}</div>
                  </div>
                </div>
              </div>

              <div className="col-xl-2 col-md-2 col-6" style={styles1}>
                <div className="wrapper count-title d-flex">
                  <div className="icon"><i className="icon-padnote"></i></div>
                  <div className="name"><strong className="text-uppercase">Presión 1 MPa</strong><span>Última Lectura</span>
                    <div className="count-number">{(s.presion_1===null||isNaN(s.presion_1))?'null':(s.presion_1.toFixed(3))}</div>
                  </div>
                </div>
              </div>
              <div className="col-xl-2 col-md-2 col-6" style={styles2}>
                <div className="wrapper count-title d-flex">
                  <div className="icon"><i className="icon-padnote"></i></div>
                  <div className="name"><strong className="text-uppercase">Digitos 2</strong><span>Última Lectura</span>
                    <div className="count-number">{(s.digitos_2===null)?'null':(s.digitos_2)}</div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-md-3 col-6" style={styles2}>
                <div className="wrapper count-title d-flex">
                  <div className="icon"><i className="icon-padnote"></i></div>
                  <div className="name"><strong className="text-uppercase">Cota 2 msnm</strong><span>Última Lectura</span>
                    <div className="count-number">{(s.cota_2===null||isNaN(s.cota_2))?'null':(s.cota_2.toFixed(3))}</div>
                  </div>
                </div>
              </div>
             
              <div className="col-xl-3 col-md-3 col-6" style={styles2}> 
                <div className="wrapper count-title d-flex">
                  <div className="icon"><i className="icon-padnote"></i></div>
                  <div className="name"><strong className="text-uppercase">Profundidad 2 mbs</strong><span>Última Lectura</span>
                    <div className="count-number">{(s.profundidad_2===null||isNaN(s.profundidad_2))?'null':(s.profundidad_2.toFixed(3))}</div>
                  </div>
                </div>
              </div>
              <div className="col-xl-2 col-md-2 col-6" style={styles2}>
                <div className="wrapper count-title d-flex">
                  <div className="icon"><i className="icon-padnote"></i></div>
                  <div className="name"><strong className="text-uppercase">Temperatura 2 ºC</strong><span>Última Lectura</span>
                    <div className="count-number">{(s.temperatura_2===null||isNaN(s.temperatura_2))?'null':(s.temperatura_2)}</div>
                  </div>
                </div>
              </div>

              <div className="col-xl-2 col-md-2 col-6" style={styles2}>
                <div className="wrapper count-title d-flex">
                  <div className="icon"><i className="icon-padnote"></i></div>
                  <div className="name"><strong className="text-uppercase">Presión 2 MPa</strong><span>Última Lectura</span>
                    <div className="count-number">{(s.presion_2===null||isNaN(s.presion_2))?'null':(s.presion_2.toFixed(3))}</div>
                  </div>
                </div>
              </div>

              <div className="col-xl-2 col-md-2 col-6" style={styles3}>
                <div className="wrapper count-title d-flex">
                  <div className="icon"><i className="icon-padnote"></i></div>
                  <div className="name"><strong className="text-uppercase">Digitos 3</strong><span>Última Lectura</span>
                    <div className="count-number">{(s.digitos_3===null)?'null':(s.digitos_3)}</div>
                  </div>
                </div>
              </div>

              <div className="col-xl-3 col-md-3 col-6" style={styles3}>
                <div className="wrapper count-title d-flex">
                  <div className="icon"><i className="icon-padnote"></i></div>
                  <div className="name"><strong className="text-uppercase">Cota 3 msnm</strong><span>Última Lectura</span>
                    <div className="count-number">{(s.cota_3===null||isNaN(s.cota_3))?'null':(s.cota_3.toFixed(3))}</div>
                  </div>
                </div>
              </div>
             
              <div className="col-xl-3 col-md-3 col-6" style={styles3}>
                <div className="wrapper count-title d-flex">
                  <div className="icon"><i className="icon-padnote"></i></div>
                  <div className="name"><strong className="text-uppercase">Profundidad 3 mbs</strong><span>Última Lectura</span>
                    <div className="count-number">{(s.profundidad_3===null||isNaN(s.profundidad_3))?'null':(s.profundidad_3.toFixed(3))}</div>
                  </div>
                </div>
              </div>
              <div className="col-xl-2 col-md-2 col-6" style={styles3}>
                <div className="wrapper count-title d-flex">
                  <div className="icon"><i className="icon-padnote"></i></div>
                  <div className="name"><strong className="text-uppercase">Temperatura 3 ºC</strong><span>Última Lectura</span>
                    <div className="count-number">{(s.temperatura_3===null||isNaN(s.temperatura_3))?'null':(s.temperatura_3)}</div>
                  </div>
                </div>
              </div>

              <div className="col-xl-2 col-md-2 col-6" style={styles3}>
                <div className="wrapper count-title d-flex">
                  <div className="icon"><i className="icon-padnote"></i></div>
                  <div className="name"><strong className="text-uppercase">Presión 3 MPa</strong><span>Última Lectura</span>
                    <div className="count-number">{(s.presion_3===null||isNaN(s.presion_3))?'null':(s.presion_3.toFixed(3))}</div>
                  </div>
                </div>
              </div>

              <div className="col-xl-2 col-md-2 col-6" style={styles4}>
                <div className="wrapper count-title d-flex">
                  <div className="icon"><i className="icon-padnote"></i></div>
                  <div className="name"><strong className="text-uppercase">Digitos 4</strong><span>Última Lectura</span>
                    <div className="count-number">{(s.digitos_4===null||isNaN(s.digitos_4))?'null':(s.digitos_4)}</div>
                  </div>
                </div>
              </div>

              <div className="col-xl-3 col-md-3 col-6" style={styles4}>
                <div className="wrapper count-title d-flex">
                  <div className="icon"><i className="icon-padnote"></i></div>
                  <div className="name"><strong className="text-uppercase">Cota 4 msnm</strong><span>Última Lectura</span>
                    <div className="count-number">{(s.cota_4===null||isNaN(s.cota_4))?'null':(s.cota_4.toFixed(3))}</div>
                  </div>
                </div>
              </div>
             
              <div className="col-xl-3 col-md-3 col-6" style={styles4}>
                <div className="wrapper count-title d-flex">
                  <div className="icon"><i className="icon-padnote"></i></div>
                  <div className="name"><strong className="text-uppercase">Profundidad 4 mbs</strong><span>Última Lectura</span>
                    <div className="count-number">{(s.profundidad_4===null||isNaN(s.profundidad_4))?'null':(s.profundidad_4.toFixed(3))}</div>
                  </div>
                </div>
              </div>
              <div className="col-xl-2 col-md-2 col-6" style={styles4}>
                <div className="wrapper count-title d-flex">
                  <div className="icon"><i className="icon-padnote"></i></div>
                  <div className="name"><strong className="text-uppercase">Temperatura 4 ºC</strong><span>Última Lectura</span>
                    <div className="count-number">{(s.temperatura_4===null||isNaN(s.temperatura_4))?'null':(s.temperatura_4)}</div>
                  </div>
                </div>
              </div>

              <div className="col-xl-2 col-md-2 col-6" style={styles4}>
                <div className="wrapper count-title d-flex">
                  <div className="icon"><i className="icon-padnote"></i></div>
                  <div className="name"><strong className="text-uppercase">Presión 4 MPa</strong><span>Última Lectura</span>
                    <div className="count-number">{(s.presion_4===null||isNaN(s.presion_4))?'null':(s.presion_4.toFixed(3))}</div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-md-3 col-6">
                <div className="wrapper count-title d-flex">
                  <div className="icon"><i className="icon-padnote"></i></div>
                  <div className="name"><strong className="text-uppercase">Fecha y Hora</strong><span>Última Lectura</span>
                    <div className="count-number">{(!s.fechalec)?(s.fechalectura):(s.fechalec)}</div>
                  </div>
                </div>
              </div>

              <div className="col-xl-3 col-md-3 col-6">
                <div className="wrapper count-title d-flex">
                  <div className="icon"><i className="icon-padnote"></i></div>
                  <div className="name"><strong className="text-uppercase">Temperatura Eagle C°</strong><span>Última Lectura</span>
                    <div className="count-number">{(s.temp_equipo===null)?'null':(s.temp_equipo)}</div>
                  </div>
                </div>
              </div>

              <div className="col-xl-3 col-md-3 col-6">
                <div className="wrapper count-title d-flex">
                  <div className="icon"><i className="icon-padnote"></i></div>
                  <div className="name"><strong className="text-uppercase">Batería V</strong><span>Última Lectura</span>
                    <div className="count-number">{(s.bateria===null)?'null':(s.bateria)}</div>
                  </div>
                </div>
              </div>

              <div className="col-xl-3 col-md-3 col-6">
                <div className="wrapper count-title d-flex">
                  <div className="icon"><i className="icon-padnote"></i></div>
                  <div className="name"><strong className="text-uppercase">Nivel de Señal dBm</strong><span>Última Lectura</span>
                    <div className="count-number">{(s.senal===null)?'null':(-1*s.senal)}</div>
                  </div>
                </div>
              </div>
            
            </div>
          </div>
        </section>
      )
        
      }

      return contadores(this.props.lecturas);
        
    }
}

export default Contadores
