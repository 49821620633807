import React from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import {ProtectedRoute} from './protected.route'
//import './App.css';
import Mapa from "./componentes/mapa/Mapa";
//import MapaG from "./componentes/mapagoogle/Mapa";
import Login from "./componentes/login/Login";
//dashboard-1
import Dashboard from './componentes/dashboard/Dashboard';
import Lecturas from './componentes/dashboard/lecturas/Lecturas';
import Informacion from './componentes/dashboard/informacion/Informacion';
import Graficas from './componentes/dashboard/graficas/Graficas';
//dashboard-2
import Dashboard2 from './componentes/dashboard2/Dashboard';
import Lecturas2 from './componentes/dashboard2/lecturas/Lecturas';
import Informacion2 from './componentes/dashboard2/informacion/Informacion';
import Graficas2 from './componentes/dashboard2/graficas/Graficas';
//dashboard-3
import Dashboard3 from './componentes/dashboard3/Dashboard';
import Lecturas3 from './componentes/dashboard3/lecturas/Lecturas';
import Informacion3 from './componentes/dashboard3/informacion/Informacion';
import Graficas3 from './componentes/dashboard3/graficas/Graficas';
//dashboard-4
import Dashboard4 from './componentes/dashboard4/Dashboard';
import Lecturas4 from './componentes/dashboard4/lecturas/Lecturas';
import Informacion4  from './componentes/dashboard4/informacion/Informacion';
import Graficas4 from './componentes/dashboard4/graficas/Graficas';
/*/dashboard 5*/
import Dashboard7 from './componentes/dashboard7/Dashboard';
import Lecturas7 from './componentes/dashboard7/lecturas/Lecturas';
import Informacion7  from './componentes/dashboard7/informacion/Informacion';
import Graficas7 from './componentes/dashboard7/graficas/Graficas';
//dashboard 6
import Dashboard6 from './componentes/dashboard6/Dashboard';
import Lecturas6 from './componentes/dashboard6/lecturas/Lecturas';
import Informacion6  from './componentes/dashboard6/informacion/Informacion';
import Graficas6 from './componentes/dashboard6/graficas/Graficas';
//admin
import ModSitios from './componentes/admin/modsitios/Sitios.js';
import ModEquipos from './componentes/admin/modequipos/Equipos.js';
import ModNoC from './componentes/admin/modnorma/Normac.js';
 

     

      
/*


  

      <ProtectedRoute path="/admin/modusuarios/" exact component={ModUsuarios} />

      <ProtectedRoute path="/dashboard/5/:id" exact component={Dashboard5} />
      <ProtectedRoute path="/lecturas/5/:id" exact component={Lecturas5} />
      <ProtectedRoute path="/informacion/5/:id" exact component={Informacion5} />
      <ProtectedRoute path="/graficas/5/:id" exact component={Graficas5} />
      
      */

function App() {
  return (
    <Router>
    <Switch>
      <Route path="/" exact component={Login} />
      <ProtectedRoute path="/mapa" exact component={Mapa} />
      
      <ProtectedRoute path="/dashboard/1/:id" exact component={Dashboard} />
      <ProtectedRoute path="/lecturas/1/:id" exact component={Lecturas} />
      <ProtectedRoute path="/informacion/1/:id" exact component={Informacion} />
      <ProtectedRoute path="/graficas/1/:id" exact component={Graficas} />

      <ProtectedRoute path="/dashboard/2/:id" exact component={Dashboard2} />
      <ProtectedRoute path="/lecturas/2/:id" exact component={Lecturas2} />
      <ProtectedRoute path="/informacion/2/:id" exact component={Informacion2} />
      <ProtectedRoute path="/graficas/2/:id" exact component={Graficas2} />
      
      <ProtectedRoute path="/dashboard/3/:id" exact component={Dashboard3} />
      <ProtectedRoute path="/lecturas/3/:id" exact component={Lecturas3} />
      <ProtectedRoute path="/informacion/3/:id" exact component={Informacion3} />
      <ProtectedRoute path="/graficas/3/:id" exact component={Graficas3} />
      
        
      <ProtectedRoute path="/dashboard/4/:id" exact component={Dashboard4} />
      <ProtectedRoute path="/lecturas/4/:id" exact component={Lecturas4} />
      <ProtectedRoute path="/informacion/4/:id" exact component={Informacion4} />
      <ProtectedRoute path="/graficas/4/:id" exact component={Graficas4} />
      
      <ProtectedRoute path="/dashboard/6/:id" exact component={Dashboard6} />
      <ProtectedRoute path="/lecturas/6/:id" exact component={Lecturas6} />
      <ProtectedRoute path="/informacion/6/:id" exact component={Informacion6} />
      <ProtectedRoute path="/graficas/6/:id" exact component={Graficas6} />     
      
      
      <ProtectedRoute path="/dashboard/7/:id" exact component={Dashboard7} />
      <ProtectedRoute path="/lecturas/7/:id" exact component={Lecturas7} />
      <ProtectedRoute path="/informacion/7/:id" exact component={Informacion7} />
      <ProtectedRoute path="/graficas/7/:id" exact component={Graficas7} />

      <ProtectedRoute path="/admin/modsitios/" exact component={ModSitios} />
      <ProtectedRoute path="/admin/modequipos/" exact component={ModEquipos} />
      <ProtectedRoute path="/admin/modnormac/" exact component={ModNoC} />
      <Route path="*" component={()=>{"404 NOT FOUND"}}/>
      </Switch>
      </Router>
    
  );
}

export default App;
