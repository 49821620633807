import React, { Component } from "react";
import Navigation from "../Navigation";
import Notificaciones from "../overview/Notificaciones";
import GCota from "../overview/GCota";
import GPresion from "../overview/GPresion";
import GTemp from "../overview/GTemp";
import GBatery from "../../dashboard/overview/GBatery";
import GSignal from "../../dashboard/overview/GSignal";
import GTempE from "../../dashboard/overview/GTempE";
import axios from "axios";
import { withRouter } from "react-router-dom";
import https from "https";
const instance = axios.create({
  httpsAgent: new https.Agent({
    rejectUnauthorized: false
  })
});
export class Graficas extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lecturas: [],
      fecha1: "",
      fecha2: "",
      sitio: ""
    };
  }

  fecha1(event) {
    this.setState({ fecha1: event.target.value });
  }
  fecha2(event) {
    this.setState({ fecha2: event.target.value });
  }
  async cargarlecturas() {
    const res = await instance.get(
      "https://api.instrutronica-iot.com/api/pruebas/lecturas3/fechas/" +
        this.state.sitio +
        "/" +
        this.state.fecha1 +
        "/" +
        this.state.fecha2
    );
    this.setState({ lecturas: res.data });
  }

  async componentDidMount() {
    var query = window.location.href;
    var sit = query.split("/");
    const res = await instance.get(
      "https://api.instrutronica-iot.com/api/pruebas/lecturas3/hoy/" + sit[5]
    );
    this.setState({ lecturas: res.data });
    this.setState({ sitio: sit[5] });
    this.interval = setInterval(async () => {
      if (localStorage.getItem("pruebas_app") == null) {
        this.props.history.push("/");
      }
    }, 1000);
  }

  render() {
    var styles = {};
    if (this.state.lecturas.length > 0) {
      styles =
        this.state.lecturas[0].presion === null ? { display: "none" } : {};
    }
    return (
      <div>
        <Navigation></Navigation>
        <div className="page">
          <header className="header">
            <nav className="navbar">
              <div className="container-fluid">
                <div className="navbar-holder d-flex align-items-center justify-content-between">
                  <div className="navbar-header">
                    <button id="toggle-btn" className="menu-btn btn-dark">
                      <i className="icon-bars"> </i>
                    </button>
                    <span href="index.html" className="navbar-brand">
                      <div className="brand-text d-none d-md-inline-block">
                        <span style={{ color: "#ccc" }}>{} </span>
                        <strong className="text-primary"> {}</strong>
                      </div>
                    </span>
                  </div>
                  <Notificaciones></Notificaciones>
                </div>
              </div>
            </nav>
          </header>
          <div className="breadcrumb-holder">
            <div className="container-fluid">
              <ul className="breadcrumb">
                <li className="breadcrumb-item">Vista General</li>
                <li className="breadcrumb-item active">Gráficas </li>
              </ul>
            </div>
          </div>

          <section className="charts">
            <div className="container-fluid">
              <header>
                <h1 className="h3 display">Gráficas </h1>
                <p>
                  Para ver datos en las gráficas elija primero las fechas y
                  luego presione Cargar Lecturas
                </p>
              </header>
              <div className="row">
                <div className="col-lg-12">
                  <div className="input-group">
                    <input
                      id="fecha1"
                      onChange={this.fecha1.bind(this)}
                      type="date"
                      className="form-control"
                      placeholder="Fecha Inicial"
                      aria-label="Fecha Inicial"
                      aria-describedby="button-addon4"
                    ></input>
                    <input
                      id="fecha2"
                      onChange={this.fecha2.bind(this)}
                      type="date"
                      className="form-control"
                      placeholder="Fecha Final"
                      aria-label="Fecha fianl"
                      aria-describedby="button-addon4"
                    ></input>
                    <div className="input-group-append" id="button-addon4">
                      <button
                        className="btn  btn-success"
                        type="button"
                        onClick={this.cargarlecturas.bind(this)}
                      >
                        Cargar Lecturas
                      </button>
                    </div>
                  </div>{" "}
                </div>
              </div>

              <div className="row">
                <GCota lecturas={this.state.lecturas} />
                <GTemp lecturas={this.state.lecturas} />
              </div>
              <div className="row" style={styles}>
                <GPresion lecturas={this.state.lecturas} />
              </div>
              <div className="row">
                <GBatery
                  lecturas={this.state.lecturas}
                  contador={this.state.contador}
                />
                <GTempE
                  lecturas={this.state.lecturas}
                  contador={this.state.contador}
                />
                <GSignal
                  lecturas={this.state.lecturas}
                  contador={this.state.contador}
                />
              </div>
            </div>
          </section>

          <footer className="main-footer">
            <div className="container-fluid">
              <div className="row">
                <div className="col-sm-6">
                  <p>Instrutrónica -Liderti &copy; 2019</p>
                </div>
                <div className="col-sm-6 text-right">
                  <p>Version 1.0.0</p>
                </div>
              </div>
            </div>
          </footer>
        </div>
      </div>
    );
  }
}

export default withRouter(Graficas);
