import React, { Component } from "react";
import Navigation from "../Navigation";
import Notificaciones from "../overview/Notificaciones";
import axios from "axios";
import { CSVLink } from "react-csv";
import { withRouter } from "react-router-dom";
import https from "https";
const instance = axios.create({
  httpsAgent: new https.Agent({
    rejectUnauthorized: false
  })
});
/*function convertirfecha(fecha) {
    if (fecha !== '') {
        //console.log(this.state.sitios[i].fechalectura);
        var fec = new Date("" + fecha + "");
        var hora = fec.toLocaleTimeString();
        var dia = fec.toLocaleDateString();
        return dia + ' ' + hora;
    } else {
        return '-';
    }
};*/

export class Lecturas extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lecturas: [],
      fecha1: "",
      fecha2: "",
      sitio: ""
    };
  }

  fecha1(event) {
    this.setState({ fecha1: event.target.value });
  }
  fecha2(event) {
    this.setState({ fecha2: event.target.value });
  }
  async cargarlecturas() {
    const res = await instance.get(
      "https://api.instrutronica-iot.com/api/pruebas/lecturas1/fechas/" +
        this.state.sitio +
        "/" +
        this.state.fecha1 +
        "/" +
        this.state.fecha2
    );
    this.setState({ lecturas: res.data });
  }

  async componentDidMount() {
    var query = window.location.href;
    var sit = query.split("/");
    const res = await instance.get(
      "https://api.instrutronica-iot.com/api/pruebas/lecturas1/hoy/" + sit[5]
    );
    this.setState({ lecturas: res.data });
    this.setState({ sitio: sit[5] });

    this.interval = setInterval(async () => {
      if (localStorage.getItem("pruebas_app") == null) {
        this.props.history.push("/");
      }
    }, 1000);
  }

  render() {
    let count = 1;
    return (
      <div>
        <Navigation></Navigation>
        <div className="page">
          <header className="header">
            <nav className="navbar">
              <div className="container-fluid">
                <div className="navbar-holder d-flex align-items-center justify-content-between">
                  <div className="navbar-header">
                    <button id="toggle-btn" className="menu-btn btn-dark">
                      <i className="icon-bars"> </i>
                    </button>
                    <span href="index.html" className="navbar-brand">
                      <div className="brand-text d-none d-md-inline-block">
                        <span style={{ color: "#ccc" }}>{} </span>
                        <strong className="text-primary"> {}</strong>
                      </div>
                    </span>
                  </div>
                  <Notificaciones></Notificaciones>
                </div>
              </div>
            </nav>
          </header>
          <div className="breadcrumb-holder">
            <div className="container-fluid">
              <ul className="breadcrumb">
                <li className="breadcrumb-item">Vista General</li>
                <li className="breadcrumb-item active">Lecturas </li>
              </ul>
            </div>
          </div>

          <section>
            <div className="container-fluid">
              <header>
                <h1 className="h3 display">Lecturas </h1>
                <p>
                  Para descargar un reporte primero cargue las lecturas y
                  después presione el botón Descargar CSV
                </p>
              </header>
              <div className="row">
                <div className="col-lg-12">
                  <div className="input-group">
                    <input
                      id="fecha1"
                      onChange={this.fecha1.bind(this)}
                      type="date"
                      className="form-control"
                      placeholder="Fecha Inicial"
                      aria-label="Fecha Inicial"
                      aria-describedby="button-addon4"
                    ></input>
                    <input
                      id="fecha2"
                      onChange={this.fecha2.bind(this)}
                      type="date"
                      className="form-control"
                      placeholder="Fecha Final"
                      aria-label="Fecha fianl"
                      aria-describedby="button-addon4"
                    ></input>
                    <div className="input-group-append" id="button-addon4">
                      <button
                        className="btn  btn-success"
                        type="button"
                        onClick={this.cargarlecturas.bind(this)}
                      >
                        Cargar Lecturas
                      </button>
                      <CSVLink
                        className="btn btn-info"
                        data={this.state.lecturas}
                        asyncOnClick={true}
                        filename={"reporte.csv"}
                      >
                        Descargar CSV
                      </CSVLink>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="card">
                    <div className="card-header">
                      <h4>Lecturas del dia de hoy</h4>
                    </div>
                    <div className="card-body">
                      <div className="table-responsive">
                        <table className="table table-striped table-hover">
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Caudal</th>
                              <th>Volumen</th>
                              <th>Fecha</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.lecturas.map(function (lectura) {
                              return (
                                <tr key={lectura._id}>
                                  <th scope="row">{count++}</th>
                                  {/*<td>{(lectura.caudal===null) ? (lectura.caudal).toLocaleString():lectura.caudal}</td>
                                                                    <td>{(lectura.volumen===null) ? (lectura.volumen).toLocaleString():lectura.volumen}</td>*/}
                                  <td>
                                    {lectura.caudal === null
                                      ? "null"
                                      : lectura.caudal}
                                  </td>
                                  <td>
                                    {lectura.volumen === null
                                      ? "null"
                                      : lectura.volumen}
                                  </td>
                                  <td>
                                    {!lectura.fechalec
                                      ? lectura.fechalectura
                                      : lectura.fechalec}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <footer className="main-footer">
            <div className="container-fluid">
              <div className="row">
                <div className="col-sm-6">
                  <p>Instrutrónica -Liderti &copy; 2019</p>
                </div>
                <div className="col-sm-6 text-right">
                  <p>Version 1.0.0</p>
                </div>
              </div>
            </div>
          </footer>
        </div>
      </div>
    );
  }
}

export default withRouter(Lecturas);
