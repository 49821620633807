import React, { Component } from "react";
import "../../dashboard/css/nav.css";
import "../../dashboard/css/font-awesome.css";
import "../../dashboard/css/fontastic.css";
import Navigation from "../Navigation";
import Notificaciones from "../../dashboard/overview/Notificaciones";
import BuscarSitio from "./BuscarSitio";
import axios from "axios";
import { ToastsContainer, ToastsStore } from "react-toasts";
import { withRouter } from "react-router-dom";
import https from "https";
const instance = axios.create({
  httpsAgent: new https.Agent({
    rejectUnauthorized: false
  })
});
export class Equipos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lecturas: {
        firmware: "-",
        imei: 0,
        marca: "-",
        modelo: "-",
        intervalo: 0,
        numerotel: "0000000000",
        acumulado: 0,
        tipo: "1",
        _id: "-"
      },

      sitioid: ""
    };
  }
  recibirDatosP = (childData) => {
    this.setState({ lecturas: childData });
  };
  onInputChange = (e) => {
    const todoestado = this.state.lecturas;
    if (e.target.name === "firmware") {
      todoestado.firmware = e.target.value;
      this.setState({ lecturas: todoestado });
    } else if (e.target.name === "imei") {
      todoestado.imei = e.target.value;
      this.setState({ lecturas: todoestado });
    } else if (e.target.name === "marca") {
      todoestado.marca = e.target.value;
      this.setState({ lecturas: todoestado });
    } else if (e.target.name === "modelo") {
      todoestado.modelo = e.target.value;
      this.setState({ lecturas: todoestado });
    } else if (e.target.name === "numerotel") {
      todoestado.numerotel = e.target.value;
      this.setState({ lecturas: todoestado });
    } else if (e.target.name === "tipo") {
      todoestado.tipo = e.target.value;
      this.setState({ lecturas: todoestado });
    } else if (e.target.name === "intervalo") {
      todoestado.intervalo = e.target.value;
      this.setState({ lecturas: todoestado });
    } else if (e.target.name === "acumulado") {
      todoestado.acumulado = e.target.value;
      this.setState({ lecturas: todoestado });
    }
  };
  actualizarDatos = async (e) => {
    const updatedNote = {
      firmware: this.state.lecturas.firmware,
      imei: this.state.lecturas.imei,
      marca: this.state.lecturas.marca,
      modelo: this.state.lecturas.modelo,
      numerotel: this.state.lecturas.numerotel,
      tipo: this.state.lecturas.tipo,
      intervalo: this.state.lecturas.intervalo,
      acumulado: this.state.lecturas.acumulado
    };
    const res = await instance.put(
      "https://api.instrutronica-iot.com/api/pruebas/equipos/" +
        this.state.lecturas._id,
      updatedNote
    );
    console.log(res.data);
    if (res.data === "Equipo Actualizado") {
      ToastsStore.success("Equipo Actualizado");
    } else {
      ToastsStore.error("Fallo Actualización");
    }
  };
  async componentDidMount() {
    this.interval = setInterval(async () => {
      const tip = JSON.parse(localStorage.getItem("tipo"));
      if (tip.tipo !== "admin") {
        this.props.history.push("/");
      }
    }, 1000);
  }
  render() {
    return (
      <div>
        <Navigation></Navigation>
        <div className="page">
          <header className="header">
            <nav className="navbar">
              <div className="container-fluid">
                <div className="navbar-holder d-flex align-items-center justify-content-between">
                  <div className="navbar-header">
                    <button id="toggle-btn" className="menu-btn btn-dark">
                      <i className="icon-bars"> </i>
                    </button>
                    <span href="index.html" className="navbar-brand">
                      <div className="brand-text d-none d-md-inline-block">
                        <span style={{ color: "#ccc" }}>{} </span>
                        <strong className="text-primary"> {}</strong>
                      </div>
                    </span>
                  </div>
                  <Notificaciones></Notificaciones>
                </div>
              </div>
            </nav>
          </header>
          <div className="breadcrumb-holder">
            <div className="container-fluid">
              <ul className="breadcrumb">
                <li className="breadcrumb-item">Vista General</li>
                <li className="breadcrumb-item active">Información </li>
              </ul>
            </div>
          </div>

          <section className="dashboard-header section-padding">
            <div className="container-fluid">
              <div className="row d-flex align-items-md-stretch">
                <div className="col-lg-12 col-md-12 flex-lg-last flex-md-first align-self-baseline">
                  <BuscarSitio recibirDatosP={this.recibirDatosP}></BuscarSitio>
                </div>
                <div className="col-12 flex-lg-last flex-md-first align-self-baseline">
                  <p>
                    Selecciona un sitio en la lista, dale clic en cargar sitio y
                    abajo modifica sus datos, presiona Actualizar para guardar
                    los cambios.
                  </p>{" "}
                  <strong>Los Cambios NO son reversibles.</strong>
                </div>

                <div className="col-lg-12 col-12 ">
                  <form>
                    <div className="form-group ">
                      <label htmlFor="firmware">Firmware</label>
                      <input
                        type="text"
                        name="firmware"
                        className="form-control"
                        onChange={this.onInputChange}
                        value={this.state.lecturas.firmware}
                      ></input>
                      <label htmlFor="imei">IMEI:</label>
                      <input
                        name="imei"
                        className="form-control"
                        type="text"
                        onChange={this.onInputChange}
                        value={this.state.lecturas.imei}
                      ></input>
                      <label htmlFor="marca">Marca:</label>
                      <input
                        name="marca"
                        className="form-control"
                        type="text"
                        onChange={this.onInputChange}
                        value={this.state.lecturas.marca}
                      ></input>
                      <label htmlFor="modelo">Modelo:</label>
                      <input
                        name="modelo"
                        className="form-control"
                        type="text"
                        onChange={this.onInputChange}
                        value={this.state.lecturas.modelo}
                      ></input>
                      <label htmlFor="intervalo">
                        Intervalo (Tiempo entre cada lectura en min):
                      </label>
                      <input
                        name="intervalo"
                        className="form-control"
                        type="text"
                        onChange={this.onInputChange}
                        value={this.state.lecturas.intervalo}
                      ></input>
                      <label htmlFor="acumulado">
                        Acumulado (Tiempo entre cada lectura en min):
                      </label>
                      <input
                        name="acumulado"
                        className="form-control"
                        type="text"
                        onChange={this.onInputChange}
                        value={this.state.lecturas.acumulado}
                      ></input>
                      <label htmlFor="numerotel">Número de Teléfono:</label>
                      <input
                        name="numerotel"
                        className="form-control"
                        type="text"
                        onChange={this.onInputChange}
                        value={this.state.lecturas.numerotel}
                      ></input>
                      <label htmlFor="tipo">Tipo:</label>
                      <input
                        name="tipo"
                        className="form-control"
                        type="text"
                        onChange={this.onInputChange}
                        value={this.state.lecturas.tipo}
                      ></input>
                    </div>
                  </form>
                </div>
                <div className="col-12 flex-lg-last flex-md-first align-self-baseline">
                  <button
                    onClick={this.actualizarDatos}
                    className="btn btn-danger"
                  >
                    Actualizar
                  </button>
                  <ToastsContainer store={ToastsStore} />
                </div>
              </div>
            </div>
          </section>

          <footer className="main-footer">
            <div className="container-fluid">
              <div className="row">
                <div className="col-sm-6">
                  <p>Instrutrónica -Liderti &copy; 2019</p>
                </div>
                <div className="col-sm-6 text-right">
                  <p>Version 1.0.0</p>
                </div>
              </div>
            </div>
          </footer>
        </div>
      </div>
    );
  }
}

export default withRouter(Equipos);
