import React, { Component } from 'react'

export class Contadores extends Component {

   
    render() {
      
     
      function contadores(s) {
       
        const styles = (s.caudal===null&&s.volumen===null ) ? {display: 'none'}:{};
        return (
          <section className="dashboard-counts section-padding">
          <div className="container-fluid">
            <div className="row">
              
              <div className="col-xl-4 col-md-4 col-6" style={styles} >
                <div className="wrapper count-title d-flex">
                  <div className="icon"><i className="icon-padnote"></i></div>
                  <div className="name"><strong className="text-uppercase">Caudal L/S</strong><span>Última Lectura</span>
                    <div className="count-number">{(s.caudal===null)?'null':(s.caudal)}</div>
                  </div>
                </div>
              </div>
             
              <div className="col-xl-4 col-md-4 col-6" style={styles}>
                <div className="wrapper count-title d-flex">
                  <div className="icon"><i className="icon-padnote"></i></div>
                  <div className="name"><strong className="text-uppercase">Volumen M3</strong><span>Última Lectura</span>
                    <div className="count-number">{(s.volumen===null)?'null':(s.volumen)}</div>
                  </div>
                </div>
              </div>
              
              <div className="col-xl-4 col-md-4 col-6">
                <div className="wrapper count-title d-flex">
                  <div className="icon"><i className="icon-padnote"></i></div>
                  <div className="name"><strong className="text-uppercase">Fecha y Hora</strong><span>Última Lectura</span>
                    <div className="count-number">{s.fechalec}</div>
                  </div>
                </div>
              </div>

              <div className="col-xl-4 col-md-4 col-6">
                <div className="wrapper count-title d-flex">
                  <div className="icon"><i className="icon-padnote"></i></div>
                  <div className="name"><strong className="text-uppercase">pH</strong><span>Última Lectura</span>
                    <div className="count-number">{(s.ph===null)?'null':(s.ph)}</div>
                  </div>
                </div>
              </div>

              <div className="col-xl-4 col-md-4 col-6">
                <div className="wrapper count-title d-flex">
                  <div className="icon"><i className="icon-padnote"></i></div>
                  <div className="name"><strong className="text-uppercase">pH mV</strong><span>Última Lectura</span>
                    <div className="count-number">{(s.phmv===null)?'null':(s.phmv)}</div>
                  </div>
                </div>
              </div>

              <div className="col-xl-4 col-md-4 col-6">
                <div className="wrapper count-title d-flex">
                  <div className="icon"><i className="icon-padnote"></i></div>
                  <div className="name"><strong className="text-uppercase">ORP mV</strong><span>Última Lectura</span>
                    <div className="count-number">{(s.orp===null)?'null':(s.orp)}</div>
                  </div>
                </div>
              </div>

              <div className="col-xl-4 col-md-4 col-6">
                <div className="wrapper count-title d-flex">
                  <div className="icon"><i className="icon-padnote"></i></div>
                  <div className="name"><strong className="text-uppercase">Conductividad Real (µS/cm)</strong><span>Última Lectura</span>
                    <div className="count-number">{(s.conductividad===null)?'null':(s.conductividad)}</div>
                  </div>
                </div>
              </div>             


              <div className="col-xl-4 col-md-4 col-6">
                <div className="wrapper count-title d-flex">
                  <div className="icon"><i className="icon-padnote"></i></div>
                  <div className="name"><strong className="text-uppercase">Conductividad Específica (µS/cm)</strong><span>Última Lectura</span>
                    <div className="count-number">{(s.conductividad_e===null)?'null':(s.conductividad_e)}</div>
                  </div>
                </div>
              </div>

                          
              <div className="col-xl-4 col-md-4 col-6">
                <div className="wrapper count-title d-flex">
                  <div className="icon"><i className="icon-padnote"></i></div>
                  <div className="name"><strong className="text-uppercase">Salinidad PSU</strong><span>Última Lectura</span>
                    <div className="count-number">{(s.salinidad===null)?'null':(s.salinidad)}</div>
                  </div>
                </div>
              </div>

              <div className="col-xl-4 col-md-4 col-6">
                <div className="wrapper count-title d-flex">
                  <div className="icon"><i className="icon-padnote"></i></div>
                  <div className="name"><strong className="text-uppercase">Sólidos Disueltos ppt</strong><span>Última Lectura</span>
                    <div className="count-number">{(s.solidos_disueltos===null)?'null':(s.solidos_disueltos)}</div>
                  </div>
                </div>
              </div>

              <div className="col-xl-4 col-md-4 col-6">
                <div className="wrapper count-title d-flex">
                  <div className="icon"><i className="icon-padnote"></i></div>
                  <div className="name"><strong className="text-uppercase">Resistividad (Ω⋅cm)</strong><span>Última Lectura</span>
                    <div className="count-number">{(s.resistividad===null)?'null':(s.resistividad)}</div>
                  </div>
                </div>
              </div>

              <div className="col-xl-4 col-md-4 col-6">
                <div className="wrapper count-title d-flex">
                  <div className="icon"><i className="icon-padnote"></i></div>
                  <div className="name"><strong className="text-uppercase">Densidad (g/cm³)</strong><span>Última Lectura</span>
                    <div className="count-number">{(s.densidad===null)?'null':(s.densidad)}</div>
                  </div>
                </div>
              </div>

              <div className="col-xl-4 col-md-4 col-6">
                <div className="wrapper count-title d-flex">
                  <div className="icon"><i className="icon-padnote"></i></div>
                  <div className="name"><strong className="text-uppercase">Turbidez (NTU) </strong><span>Última Lectura</span>
                    <div className="count-number">{(s.turbiedad===null)?'null':(s.turbiedad)}</div>
                  </div>
                </div>
              </div>

              <div className="col-xl-4 col-md-4 col-6">
                <div className="wrapper count-title d-flex">
                  <div className="icon"><i className="icon-padnote"></i></div>
                  <div className="name"><strong className="text-uppercase">Sólidos en Suspensión (mg/L)</strong><span>Última Lectura</span>
                    <div className="count-number">{(s.solidos_susp===null)?'null':(s.solidos_susp)}</div>
                  </div>
                </div>
              </div>

              <div className="col-xl-4 col-md-4 col-6">
                <div className="wrapper count-title d-flex">
                  <div className="icon"><i className="icon-padnote"></i></div>
                  <div className="name"><strong className="text-uppercase">	Concentración RDO (mg/L) </strong><span>Última Lectura</span>
                    <div className="count-number">{(s.oxigeno_dis===null)?'null':(s.oxigeno_dis)}</div>
                  </div>
                </div>
              </div>

              
              <div className="col-xl-4 col-md-4 col-6">
                <div className="wrapper count-title d-flex">
                  <div className="icon"><i className="icon-padnote"></i></div>
                  <div className="name"><strong className="text-uppercase">Saturación RDO (%Sat)</strong><span>Última Lectura</span>
                    <div className="count-number">{(s.saturacion_ox===null)?'null':(s.saturacion_ox)}</div>
                  </div>
                </div>
              </div>

              <div className="col-xl-4 col-md-4 col-6">
                <div className="wrapper count-title d-flex">
                  <div className="icon"><i className="icon-padnote"></i></div>
                  <div className="name"><strong className="text-uppercase">Presión Parcial de Oxígeno (Torr)</strong><span>Última Lectura</span>
                    <div className="count-number">{(s.presion_par_ox===null)?'null':(s.presion_par_ox)}</div>
                  </div>
                </div>
              </div>

              <div className="col-xl-4 col-md-4 col-6">
                <div className="wrapper count-title d-flex">
                  <div className="icon"><i className="icon-padnote"></i></div>
                  <div className="name"><strong className="text-uppercase">Temperatura (°C)</strong><span>Última Lectura</span>
                    <div className="count-number">{(s.temperatura===null)?'null':(s.temperatura)}</div>
                  </div>
                </div>
              </div>

              <div className="col-xl-4 col-md-4 col-6">
                <div className="wrapper count-title d-flex">
                  <div className="icon"><i className="icon-padnote"></i></div>
                  <div className="name"><strong className="text-uppercase">Presión Barométrica (mm Hg)</strong><span>Última Lectura</span>
                    <div className="count-number">{(s.presion_bar===null)?'null':(s.presion_bar)}</div>
                  </div>
                </div>
              </div>


              <div className="col-xl-4 col-md-4 col-6">
                <div className="wrapper count-title d-flex">
                  <div className="icon"><i className="icon-padnote"></i></div>
                  <div className="name"><strong className="text-uppercase">Elevación</strong><span>Última Lectura</span>
                    <div className="count-number">{(s.elevacion===null)?'null':(s.elevacion)}</div>
                  </div>
                </div>
              </div>


              <div className="col-xl-4 col-md-4 col-6">
                <div className="wrapper count-title d-flex">
                  <div className="icon"><i className="icon-padnote"></i></div>
                  <div className="name"><strong className="text-uppercase">Temperatura Eagle C°</strong><span>Última Lectura</span>
                    <div className="count-number">{(s.temp_equipo===null)?'null':(s.temp_equipo)}</div>
                  </div>
                </div>
              </div>

              <div className="col-xl-4 col-md-4 col-6">
                <div className="wrapper count-title d-flex">
                  <div className="icon"><i className="icon-padnote"></i></div>
                  <div className="name"><strong className="text-uppercase">Batería V</strong><span>Última Lectura</span>
                    <div className="count-number">{(s.bateria===null)?'null':(s.bateria)}</div>
                  </div>
                </div>
              </div>

              <div className="col-xl-4 col-md-4 col-6">
                <div className="wrapper count-title d-flex">
                  <div className="icon"><i className="icon-padnote"></i></div>
                  <div className="name"><strong className="text-uppercase">Nivel de Señal dBm</strong><span>Última Lectura</span>
                    <div className="count-number">{(s.senal===null)?'null':(-1*s.senal)}</div>
                  </div>
                </div>
              </div>
            
            </div>
          </div>
        </section>
      )
        
      }

      return contadores(this.props.lecturas);
        
    }
}

export default Contadores
