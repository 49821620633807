import React, { Component } from 'react'
import diagrama from './diag1v2.png';
export class Contadores extends Component {
  /*{(s.d1===null)?'null':(s.d1)}*/
   
    render() {
      

      function contadores(s) {
       
        
        return (
          <section className="dashboard-counts section-padding " >
          <div className="container">
            
              <div className="row align-items-end">

              <div className="col-xl-3 col-md-6 col-6">
                <div className="wrapper count-title d-flex aguamed gradiente1energia rcorners1">
                  <div className="name"><strong className="text-uppercase medletra">Arranque</strong>
                    <div className="count-number rcorners1digit digitos">{(s.io1===null)?'null':((s.io1===true)?'Encendido':'Apagado')}</div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-md-6 col-6">
                <div className="wrapper count-title d-flex aguamed gradiente1energia rcorners1">
                  <div className="name"><strong className="text-uppercase medletra">Energía Real</strong>
                    <div className="count-number rcorners1digit digitos">{(s.d5===null)?'null':(s.d5)}</div>
                  </div>
                </div>
              </div>

              <div className="col-xl-3 col-md-6 col-6">
                <div className="wrapper count-title d-flex aguamed gradiente1energia rcorners1">
                  <div className="name"><strong className="text-uppercase medletra">Potencia A.</strong>
                    <div className="count-number rcorners1digit digitos">{(s.d6===null)?'null':(s.d6)}</div>
                  </div>
                </div>
              </div>

              <div className="col-xl-3 col-md-6 col-6">
                <div className="wrapper count-title d-flex aguamed gradiente1energia rcorners1">
                  <div className="name"><strong className="text-uppercase medletra">Potencia R.</strong>
                    <div className="count-number rcorners1digit digitos">{(s.d7===null)?'null':(s.d7)}</div>
                  </div>
                </div>
              </div>

              <div className="col-xl-3 col-md-6 col-6">
                <div className="wrapper count-title d-flex aguamed gradiente1energia rcorners1">
                  <div className="name"><strong className="text-uppercase medletra">Voltaje V L1-L2</strong>
                    <div className="count-number rcorners1digit digitos">{(s.d8===null)?'null':(s.d8)}</div>
                  </div>
                </div>
              </div>

              <div className="col-xl-3 col-md-6 col-6">
                <div className="wrapper count-title d-flex aguamed gradiente1energia rcorners1">
                  <div className="name"><strong className="text-uppercase medletra">Voltaje V L2-L3</strong>
                    <div className="count-number rcorners1digit digitos">{(s.d9===null)?'null':(s.d9)}</div>
                  </div>
                </div>
              </div>

              <div className="col-xl-3 col-md-6 col-6">
                <div className="wrapper count-title d-flex aguamed gradiente1energia rcorners1">
                  <div className="name"><strong className="text-uppercase medletra">Voltaje V L1-L3</strong>
                    <div className="count-number rcorners1digit digitos">{(s.d10===null)?'null':(s.d10)}</div>
                  </div>
                </div>
              </div>

            
              
              
            

            <div className="col-xl-9  col-md-8 col-12">
            <img src={diagrama} alt="Logo Mina" style={{maxWidth : "100%"}} className="img-fluid"/>
            </div>
            
            
              <div className="col-xl-3  col-md-2 col-12 ">
              <div className="col-xl-3 col-md-3 col-12">
                <div className="wrapper count-title d-flex aguamed gradiente2agua rcorners1">
                  <div className="name"><strong className="text-uppercase medletra" >Caudal L/S</strong>
                    <div className="count-number rcorners1digit digitos">{(s.d1===null)?'null':(s.d1)}</div>
                  </div>
                </div>
              </div>
             
              <div className="col-xl-3 col-md-3 col-12">
                <div className="wrapper count-title d-flex aguamed gradiente2agua rcorners1">
                  <div className="name"><strong className="text-uppercase medletra">Volumen M3</strong>
                    <div className="count-number rcorners1digit digitos">{(s.d2===null)?'null':(s.d2)}</div>
                  </div>
                </div>
              </div>              
              

              <div className="col-xl-3 col-md-3 col-12">
                <div className="wrapper count-title d-flex aguamed gradiente2agua rcorners1">
                  <div className="name"><strong className="text-uppercase medletra">Nivel M</strong>
                    <div className="count-number rcorners1digit digitos">{(s.d3===null)?'null':(s.d3)}</div>
                  </div>
                </div>
              </div>

              <div className="col-xl-3 col-md-3 col-12">
                <div className="wrapper count-title d-flex aguamed gradiente2agua rcorners1">
                  <div className="name"><strong className="text-uppercase medletra">Presión PSI</strong>
                    <div className="count-number rcorners1digit digitos">{(s.d4===null)?'null':(s.d4)}</div>
                  </div>
                </div>
              </div>

              </div>
              </div>
          </div>
        </section>
      )
        
      }

      return contadores(this.props.lecturas);
        
    }
}

export default Contadores
