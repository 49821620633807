import React, { Component } from 'react'

export class VolSemanal extends Component {
    render() {
      
        return (
            <div className="col-lg-4">
              
              <div className="card income text-center">
                <div className="icon"><i className="icon-line-chart"></i></div>
                <div className="number">{(this.props.volsem2)}</div><strong className="text-primary">Volumen 2 Semanal M3</strong>
                <p>Volumen consumido esta semana.</p>
              </div>
            </div>
        )
    }
}

export default VolSemanal
