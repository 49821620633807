import {
    destroyStorage,
    getFromStorage
  } from './componentes/util/storage';
class Auth {
    constructor(){
        this.autenticado=false;
    }


login(cb){
    this.autenticado=true;    
}

logout(cb){
    
    const obj = destroyStorage('pruebas_app');
    if (obj==null) {
      
        this.autenticado=false;
         
    } else {
        this.autenticado=false;
    }
    cb();
}
isAutenticado(){
    const obj = getFromStorage('pruebas_app');
    if (obj && obj.token) {
      
        this.autenticado=true;
         
    } else {
        this.autenticado=false;
    }
   return this.autenticado;    
}
}
export default new Auth()